import { useCallback, useState } from "react"

import { IListCampaign } from "~/dto/IListCampaign";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import useCustomToast from "~/hooks/@global/useCustomToast";
import campaignService from "~/services/campaign.service";


export const useListCampaign = () => {

  const toast = useCustomToast();
  const { account } = useConnectWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [listCampaign, setListCampaign] = useState<IListCampaign[]>([]);

  const loadListCampaign = useCallback(async (pageIndex: number,) => {
    setIsLoading(true);
    try {

      const rs = await campaignService.listCampaign({
        pageIndex,
        pageSize: 48,
      });

      setListCampaign(rs.data);
      setTotal(rs.total);
      setIsLoading(false);

    } catch (error) {
      console.log(`-------------------`);
      console.log(error);
      console.log(`-------------------`);
      setIsLoading(false);
    }
  }, [])

  const loadListCollectionMissionCampaign = useCallback(async (pageIndex: number,) => {
    setIsLoading(true);
    try {

      if (account) {
        const rs = await campaignService.listCollectionMissionCampaign({
          evmAddress: account,
          pageIndex,
          pageSize: 48,
        });

        setListCampaign(rs.data);
        setTotal(rs.total);
        setIsLoading(false);
      }


    } catch (error) {
      console.log(`-------------------`);
      console.log(error);
      console.log(`-------------------`);
      setIsLoading(false);
    }
  }, [account])

  return {
    isLoading,
    listCampaign,
    total,
    loadListCampaign,
    loadListCollectionMissionCampaign,
  }
}
