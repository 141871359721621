import { Box, HStack, Input, SimpleGrid, Stack, Tab, TabList, TabPanel, TabPanels, Tabs, VStack, Text, Container, Heading, Flex, Spinner } from "@chakra-ui/react";
import { FC, useCallback, useEffect, useState } from "react";
import ManagerLayout from "~/layouts/ManagerLayout";
import WrapperHeaderMobile from "~/layouts/MasterLayout/WrapperHeaderMobile";

import { useNavigate } from "react-router-dom";
import PrimaryButton from "~/components/PrimaryButton";
import { MANAGER_ROUTERS } from "~/routes/routes";
import NftCollectionCard from "./NftCollectionCard";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import { useManagerCollections } from "./hooks/useManagerCollections";
import { usePagination } from '@ajna/pagination';
import PaginationComponent from "~/components/Pagination";
import BoxLayout from "~/components/BoxLayout";
import CreateCollectionForm from "./CreateCollectionView/CreateCollectionForm";
import MainLayout from "~/layouts/MainLayout";

type IManagerViewProps = {};

const ManagerView: FC<IManagerViewProps> = (props: IManagerViewProps) => {


  const navigate = useNavigate();
  const { account: managerAddress } = useConnectWallet();

  const [keyword, setKeyword] = useState(null);
  const [activeTab, setActiveTab] = useState(0);

  const { data, loadData, total, isLoading } = useManagerCollections({ managerAddress });
  const pageSize = 20;

  useEffect(() => {
    if (managerAddress) {
      loadData(1, keyword)
    }
  }, [managerAddress])

  useEffect(() => {
    if (managerAddress) {
      if (data.length <= 0) {
        setActiveTab(1)
      } else {
        setActiveTab(0)
      }
    }
  }, [data, data.length, managerAddress])


  const { pages, pagesCount, currentPage, setCurrentPage } = usePagination({
    total,
    limits: {
      outer: 2,
      inner: 2,
    },
    initialState: {
      pageSize,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handlePageChange = useCallback(
    (nextPage: number): void => {
      // -> request new data using the page number
      setCurrentPage(nextPage)
      console.log('request new data with ->', nextPage)
      loadData(nextPage, keyword);
    },
    [keyword, loadData, setCurrentPage],
  )


  const renderManagerCollections = () => {

    if (!data.length) {
      return (
        <VStack
          w="full"
          p={{ base: '10px', lg: '15px 20px', }}
          bg="rgba(255, 255, 255, 0.7)"
          borderRadius="8px"
          border={{ md: "1px solid #ffd3cb" }}
          boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
        >

          <Box h="20vh" />

          <Text
            fontWeight={"500"}
            fontSize={{ base: "18px", md: "20px" }}
            lineHeight="21px"
            color="black.light"
            letterSpacing="-0.5px"
          >
            You don't collection
          </Text>

          <PrimaryButton
            w="fit-content"
            onClick={() => {
              setActiveTab(1)
            }}
          >
            Create Now
          </PrimaryButton>

          <Box h="20vh" />

        </VStack>
      )
    }

    return (
      <Box
        w="full"
        p={{ base: '10px', lg: '15px 20px', }}
        bg="rgba(255, 255, 255, 0.7)"
        borderRadius="8px"
        border={{ md: "1px solid #ffd3cb" }}
        boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
      >

        <SimpleGrid
          columns={{
            base: 1,
            md: 2,
            xl: 1
          }}
          spacing={4}
        >
          {data.map((item, idx) => {
            return <NftCollectionCard key={idx} {...item} />;
          })}
        </SimpleGrid>


        {total > 0 && total > pageSize && (
          <Stack justify="center">
            <Box>
              <PaginationComponent
                pagesCount={pagesCount}
                currentPage={currentPage}
                isDisabled={false}
                onPageChange={handlePageChange}
                pages={pages}
              />
            </Box>
          </Stack>
        )}

      </Box>
    )
  }

  return (
    <ManagerLayout>
      <WrapperHeaderMobile />

      <BoxLayout>

        <Box
          overflow="hidden"
        >

          <Container maxW={'3xl'}>
            <Stack
              as={Box}
              textAlign={'center'}
              spacing={{ base: 4, md: 6 }}
            >

              <Heading
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                lineHeight={'110%'}
              >
                NFT Social Engagement
              </Heading>

              <VStack
                overflow="hidden"
                alignItems={"start"}
                textAlign={"start"}
                spacing={4}
              >

                <Text color={'gray.500'}>
                  • Important Note:
                  <br />
                  - To set up the Social Engagement Campaign for a new NFT collection, this Collection must be already set up.
                  <br />
                  - Only 01 Social eEngagement Campaign can be created per NFT Collection.
                </Text>

                <Text color={'gray.500'}>
                  • Guideline:
                  <br />
                  - Step 1: Deploy an NFT Collection on the NFTFeed platform.
                  <br />
                  -Step 2: Set up a social engagement campaign for the NFT collection to boost traffic on the previously created collection.
                </Text>

              </VStack>

            </Stack>

          </Container>

        </Box>

        <VStack
          pt="30px"
          w="full"
          spacing={4}
        >

          {isLoading ?
            <VStack py={{ base: "20vh" }}>
              <Flex flex={1} justifyContent="center" alignItems="center">
                <Spinner color="#ee3824" size="lg" />
              </Flex>
            </VStack>
            : <>
              <Box
                w="full"
                p={{ base: '10px', lg: '15px 20px', }}
                bg="white"
                borderRadius="8px"
                border={{ md: "1px solid #ffd3cb" }}
                boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
              >

                <SimpleGrid
                  w="full"
                  spacing={2}
                  columns={{ base: 1, md: 2 }}
                  py={"20px"}
                >

                  <HStack>

                    <Input
                      borderColor="#448AFF !important"
                      borderWidth="1px"
                      borderStyle="solid"
                      placeholder="Search collection name or collection address"
                      borderRadius="10px"
                      fontSize="16px"
                      lineHeight="20px"
                      bg="#F7F9FA"
                      value={keyword}
                      onChange={(e) => { setKeyword(e.target.value) }}
                    />

                    <PrimaryButton
                      w={"150px"}
                      onClick={async () => {
                        await loadData(currentPage, keyword);
                      }}
                    >
                      Search
                    </PrimaryButton>

                  </HStack>

                  <VStack
                    w={"full"}
                    alignItems={"end"}
                  >
                    <HStack>

                      <PrimaryButton
                        w={"150px"}
                        onClick={() => navigate(`${MANAGER_ROUTERS.CREATE_COLLECTION}`)}
                      >
                        Create Collection
                      </PrimaryButton>

                      <PrimaryButton
                        color={"#fff"}
                        w={"150px"}
                        onClick={() => navigate(`${MANAGER_ROUTERS.AGGREGATOR}`)}
                      >
                        NFT Aggregator
                      </PrimaryButton>

                    </HStack>
                  </VStack>

                </SimpleGrid>

              </Box>

              <Tabs
                w="full"
                isFitted
                variant='enclosed'
                index={activeTab}
                onChange={(e) => { setActiveTab(e) }}
                gap={4}
              >

                <TabList>
                  <Tab
                    mx="2px"
                    color={`#ee3824`}
                    background={"#fff"}
                    borderColor={`#ee3824`}
                    _selected={{
                      color: `#fff`,
                      background: "#ee3824 !important"
                    }}
                    _active={{
                      background: "#ee3824 !important"
                    }}
                  >
                    Manager Collection
                  </Tab>
                  <Tab
                    mx="2px"
                    color={`#ee3824`}
                    background={"#fff"}
                    borderColor={`#ee3824`}
                    _selected={{
                      color: `#fff`,
                      background: "#ee3824 !important"
                    }}
                    _active={{
                      background: "#ee3824 !important"
                    }}
                  >
                    Create Collection
                  </Tab>
                </TabList>

                <TabPanels>

                  <TabPanel>
                    {renderManagerCollections()}
                  </TabPanel>

                  <TabPanel>

                    <Box
                      w="full"
                      p={{ base: '10px', lg: '15px 20px', }}
                      bg="rgba(255, 255, 255, 0.7)"
                      borderRadius="8px"
                      border={{ md: "1px solid #ffd3cb" }}
                      boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
                    >
                      <CreateCollectionForm />
                    </Box>

                  </TabPanel>

                </TabPanels>

              </Tabs>
            </>

          }



        </VStack>

      </BoxLayout>

    </ManagerLayout>
  );
};
export default ManagerView;
