import { Container, Stack, Heading, VStack, Box, Text, SimpleGrid, HStack, Image, Button, Avatar, AvatarBadge } from "@chakra-ui/react"
import { Fragment, useCallback, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import BoxLayout from "~/components/BoxLayout"
import PaginationComponent from "~/components/Pagination"
import { useConnectWallet } from "~/hooks/@global/useConnectWallet"
import useCustomToast from "~/hooks/@global/useCustomToast"
import useWindowSize from "~/hooks/useWindowSize"
import MainLayout from "~/layouts/MainLayout"
import WrapperHeaderMobile from "~/layouts/MasterLayout/WrapperHeaderMobile"
import { usePagination } from "@ajna/pagination"
import { useListCampaign } from "./hooks/useListCampaign"
import { formatMoney } from "~/utils"
import dayjs from "dayjs"
import { MAIN_ROUTERS, MANAGER_ROUTERS } from "~/routes/routes"
import { getRandomColor, wlAccounts } from "~/common/code.helper"
import { CHAIN_CODE } from "~/@config/chain-code"
import { EVM_CHAIN_LIST } from "~/@config/chain-list"


export const SocialEngagementView = () => {

  const { width, height } = useWindowSize();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const { account } = useConnectWallet();

  const {
    isLoading,
    listCampaign,
    total,
    loadListCampaign,
  } = useListCampaign();

  const isWl = account && wlAccounts.includes(account.toLowerCase());

  useEffect(() => { loadListCampaign(1) }, [loadListCampaign]);

  const { pages, pagesCount, currentPage, setCurrentPage } = usePagination({
    total,
    limits: {
      outer: 2,
      inner: 2,
    },
    initialState: {
      pageSize: 1,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handlePageChange = useCallback(
    (nextPage: number): void => {
      // -> request new data using the page number
      setCurrentPage(nextPage)
      console.log('request new data with ->', nextPage)
      loadListCampaign(nextPage);
    },
    [setCurrentPage,],
  )

  return (
    <MainLayout>

      <WrapperHeaderMobile />

      <BoxLayout>

        <Box
          overflow="hidden"
        >

          <Container maxW={'3xl'}>
            <Stack
              as={Box}
              textAlign={'center'}
              spacing={{ base: 4, md: 6 }}
            >

              <Heading
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                lineHeight={'110%'}>
                NFT Social Engagement
              </Heading>

              <VStack
                overflow="hidden"
                spacing={4}
              >

                <Text color={'gray.500'}>
                  NFT Social Engagement feature enables the creation of giveaway activities to increase impressions and engage users in sharing information about NFT collections by completing quests and sharing it on their social media.
                </Text>


                <Button
                  colorScheme={'green'}
                  rounded={'full'}
                  px={6}
                  bg="yellow.primary !important"
                  color="black.1d"
                  w={{ base: "full", md: "fit-content" }}
                  fontSize={{ base: "13px", md: "16px" }}
                  onClick={() => {
                    navigate(MANAGER_ROUTERS.MANAGER)
                  }}
                >
                  Create Social Engagement Campaign
                </Button>

              </VStack>

            </Stack>

          </Container>

        </Box>

        <Box
          mt="30px"
          w="full"
        >

          <VStack w="full" pt="30px" spacing={6}>

            <SimpleGrid
              w="full"
              spacing={3}
              columns={{ base: 1, md: 2, lg: 4, '3xl': 5 }}
            >

              {listCampaign.map((item, idx) => {

                const isEnd = (new Date()).getTime() > (new Date(item.endTime)).getTime();

                return (
                  <Box
                    position={"relative"}
                    key={idx}
                  >

                    <Box
                      h={isWl ? "290px" : "260px"}
                      bg="#fff"
                      borderWidth="2px"
                      borderStyle={"solid"}
                      borderColor={getRandomColor()}
                      borderRadius={"8px"}
                      transition="all 500ms ease-in-out"
                    />

                    <VStack
                      h={isWl ? "290px !important" : "260px !important"}
                      bottom={0}
                      position={"absolute"}
                      w="full"
                      p={{ base: "15px", }}
                      bg={isEnd ? 'rgb(0, 0, 0,0.1)' : '#fff'}
                      borderRadius="8px"
                      border={{ base: "2px solid #ffd3cb" }}
                      boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
                      cursor={{ lg: "pointer" }}
                      onClick={() => {
                        navigate(`${MAIN_ROUTERS.NFT_COLLECTION}/${item.chainId}/${item.address}`)
                      }}
                      transition="all 500ms ease-in-out"
                      _hover={{
                        transform: "translate(-10px, -10px)"
                      }}
                    >

                      <HStack>
                        <Avatar src={item.collectionLogo}>
                          <AvatarBadge boxSize='1.25em'>
                            <Image
                              src={EVM_CHAIN_LIST[item.chainId]?.logo}
                              borderRadius={"24px"}
                            />
                          </AvatarBadge>
                        </Avatar>
                        <Text
                          fontWeight={"600"}
                          fontSize={{ base: "16px" }}
                          color="black.light"
                          letterSpacing="-0.5px"
                          textAlign={"center"}
                          opacity={.8}
                        >
                          {item.collectionName}
                        </Text>
                      </HStack>

                      <Text
                        fontWeight={"600"}
                        fontSize={{ base: "18px" }}
                        color="black.light"
                        letterSpacing="-0.5px"
                        textAlign={"center"}
                      >
                        {item.campaignName}
                      </Text>

                      <Text
                        p="4px 12px"
                        color={"#fff"}
                        // bg="#ee3824"
                        border='2px solid #ffd3cb'
                        borderRadius={"8px"}
                        fontWeight={700}
                        fontSize={{ base: "16px", md: "20px" }}
                        lineHeight="21px"
                        letterSpacing="-0.5px"
                        className="textPrimary"
                      >
                        {formatMoney(item?.totalReward?.toString())} {item.chainId === CHAIN_CODE.BASE_MAINNET ? "USDC" : item.currency}
                      </Text>

                      <HStack
                        w="full"
                        alignItems={"center"}
                        justifyContent={"center"}
                      >
                        <Text
                          p="4px 12px"
                          border='1px solid #dedede'
                          borderRadius={"4px"}
                          fontSize={"14px"}
                          fontWeight={500}
                          className="textPrimary"
                        >
                          {`Participants (${formatMoney(item?.totalUserJoined?.toString()).toString() || 0})`}
                        </Text>
                        <Text
                          p="4px 12px"
                          border='1px solid #dedede'
                          borderRadius={"4px"}
                          fontSize={"12px"}
                          fontWeight={500}
                          color={isEnd ? "#000" : "green"}
                        >
                          {isEnd ? "Ended" : "Live"}
                        </Text>
                      </HStack>

                      <HStack
                        w="full"
                        alignItems={"start"}
                        justifyContent={"center"}
                      >
                        <Text
                          p="4px 12px"
                          border='1px solid #dedede'
                          borderRadius={"4px"}
                          fontSize={"12px"}
                          fontWeight={500}
                          textAlign={"left"}
                        >
                          {`${item?.startTime ? dayjs(new Date(item.startTime).toUTCDate()).format("YYYY/MM/DD HH:mm") : null}
                         -
                        ${item?.endTime ? dayjs(new Date(item.endTime).toUTCDate()).format("YYYY/MM/DD HH:mm") : null}
                        `}
                          (UTC)
                        </Text>
                      </HStack>

                      {isWl &&
                        <Text
                          p="4px 12px"
                          border='1px solid red'
                          borderRadius={"4px"}
                          fontSize={"12px"}
                          fontWeight={500}
                          textAlign={"left"}
                          color={"red"}
                        >
                          Reminder {`
                        ${item?.endTime ?
                              (isEnd ? 0 : dayjs(item.endTime).diff(dayjs(), 'day'))
                              : null}
                        `}
                          day
                        </Text>
                      }

                    </VStack>

                  </Box>
                )
              })
              }

            </SimpleGrid>

            {!isLoading && listCampaign.length > 48 &&
              <PaginationComponent
                pagesCount={pagesCount}
                currentPage={currentPage}
                isDisabled={isLoading}
                onPageChange={handlePageChange}
                pages={pages}
              />
            }

          </VStack>

        </Box>

      </BoxLayout>

      <Box h="20vh" />

    </MainLayout>
  )
}
