

import { HStack, VStack, Text, Box, Grid, GridItem, Divider } from "@chakra-ui/react";
import { useListCampaign } from "../SocialEngagementView/hooks/useListCampaign";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MAIN_ROUTERS } from "~/routes/routes";
import { GiCheckMark } from "react-icons/gi";


export const QuestMissions = () => {

  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const {
    isLoading,
    listCampaign,
    loadListCollectionMissionCampaign,
  } = useListCampaign();

  useEffect(() => { loadListCollectionMissionCampaign(1) }, [loadListCollectionMissionCampaign]);

  return (
    <VStack
      w="full"
      p={{ base: "10px", md: "20px" }}
      bg="#fff"
      borderRadius="20px"
      border={{ base: "1px solid #000" }}
      boxShadow="0px 10px 0px 0px #ffd3cb"
      alignItems={"start"}
    >

      <Text
        color="#000"
        fontWeight={700}
        textAlign="center"
        fontSize="20px"
        lineHeight="24px"
        bg="#ffd3cb9e"
        borderRadius={"8px"}
        px="4px"
      >
        Social Engagement Campaign Missions
      </Text>

      <VStack
        mt="20px"
        w='full'
        spacing={4}
        h="300px"
        overflowY={"auto"}
        p="10px"
      >

        {!listCampaign.length ?
          <Box
            mt="40px"
            bg="#ffd3cb9e"
            borderRadius={"8px"}
            p="3px 5px"
          >
            <Text
              color="#000"
              fontWeight={600}
              textAlign="center"
              fontSize="18px"
              lineHeight="24px"
            >
              Data Empty!
            </Text>
          </Box>
          :
          <>
            {listCampaign.map((item, idx) => {

              return (
                <Grid
                  key={idx}
                  gap={2}
                  templateColumns="repeat(7, 1fr)"
                  w="full"
                  justifyContent={"space-between"}
                  border={{ base: "1px solid #000" }}
                  p="10px"
                  borderRadius="20px"
                  cursor={{ lg: "pointer" }}
                  onClick={() => {
                    window.open(`${MAIN_ROUTERS.NFT_COLLECTION}/${item.chainId}/${item.address}`)
                  }}
                  _hover={{
                    boxShadow: "0px 5px 0px 0px #000"
                  }}
                >

                  <GridItem
                    w='full'
                    colSpan={{ base: 7, md: 5 }}
                  >
                    <Text
                      color="#000"
                      fontWeight={500}
                      textAlign="left"
                      fontSize="16px"
                      lineHeight="24px"
                      noOfLines={1}
                    >
                      {item.campaignName}
                    </Text>
                  </GridItem>
                  <GridItem
                    w='full'
                    colSpan={{ base: 7, md: 2 }}
                  >
                    <VStack
                      w='full'
                      alignItems={{ base: "center", md: 'end' }}
                    >
                      <HStack>
                        <Box
                          bg="#ffd3cb9e"
                          borderRadius={"8px"}
                          p="3px 5px"
                        >
                          <Text
                            color="#000"
                            fontWeight={600}
                            textAlign="center"
                            fontSize="14px"
                            lineHeight="24px"
                          >
                            +{item.mintPoint} Points
                          </Text>
                        </Box>

                        {item.isQuestFinish !== 0 &&
                          <GiCheckMark
                            color="red"
                            fontSize={"24px"}
                          />
                        }

                      </HStack>
                    </VStack>
                  </GridItem>

                </Grid>
              )
            })}
          </>

        }

      </VStack>

      <Divider variant="dashed" borderBottomColor={"#000"} mt="15px" />

      <Text
        fontWeight={400}
        fontSize={{ base: "13px" }}
        lineHeight={{ base: "18px" }}
        textAlign={"center"}
        alignSelf={"center"}
      >
        Users who previously finished Social Engagement campaigns before the Point Hunter event will get a snapshot and extra points added later.
      </Text>

    </VStack>
  )
}
