import { useState, useCallback, useEffect } from 'react';
import { getMessageErrorBlockChain, parseAmountToken } from '~/common/block-chain.helper';
import { ApiException } from '~/@core/dto';
import { getExplorer } from '~/@web3-config/evm';
import { Contract } from "ethers";
import { configEnv } from "~/@config";
import { MintConfig } from '~/dto/nft-project.dto';
import useCustomToast from '~/hooks/@global/useCustomToast';
import { useConnectWallet } from '~/hooks/@global/useConnectWallet';
import { FeedAgg__factory } from '~/abi/factories/FeedAgg__factory';
import { FeedAgg } from '~/abi/FeedAgg';


export const useCrawlMintNft = (props: {
  address: string,
  chainId: number,
  mintConfig: MintConfig
}) => {

  const { address, chainId, mintConfig } = props;
  const toast = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);
  const { account, chainId: chainIdActive, provider } = useConnectWallet();

  const mintNft = useCallback(async (factorPoint?: string) => {
    setIsLoading(true);
    try {

      if (account) {

        const signer = provider.getSigner();

        const { transactionHash, } = await (
          await signer.sendTransaction({
            data: `${mintConfig.callData}`,
            to: mintConfig.to,
            value: parseAmountToken(mintConfig.value),
          }
          ).catch(err => {
            throw new ApiException(getMessageErrorBlockChain(err))
          })
        ).wait()
          .catch(err => {
            throw new ApiException(getMessageErrorBlockChain(err))
          });
        const txLink = `${getExplorer(chainId)}/tx/${transactionHash}`.trim();

        console.log('--------link TX-----------');
        console.log({
          txLink
        });
        console.log('-------------------');

        // thu phí
        {
          const { FEED_AGG } = configEnv().customSMC[chainId]
          const rexFeeCt = new Contract(FEED_AGG, FeedAgg__factory.abi, provider) as FeedAgg;
          const value = await rexFeeCt.getFeeByQty(mintConfig.qty);

          const { transactionHash } = await (
            await rexFeeCt.connect(signer).transactionVerification(address, mintConfig.qty, { value }).catch(err => { throw new ApiException(getMessageErrorBlockChain(err)) })
          ).wait()
            .catch(err => {
              throw new ApiException(getMessageErrorBlockChain(err))
            });
          console.log(`---------TX take Fee----------`);
          console.log({ transactionHash });
          console.log(`-------------------`);
        }

        toast.success({
          title: "Success",
          subTitle: 'Mint Success',
          description: `${getExplorer(chainId)}/tx/${transactionHash}`.trim(),
        })

        setIsLoading(false);

      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      toast.handleErrorBlockChain(error)
    }

    setIsLoading(false);
  }, [account, address, chainId, mintConfig.callData, mintConfig.qty, mintConfig.to, mintConfig.value, provider,]);

  return {
    isLoading,
    mintNft,
  }
}

