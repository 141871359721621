import { Box, SimpleGrid, Text, VStack, Image, Heading, HStack, Input, InputGroup, InputRightAddon, Grid, GridItem, Container, Stack, Tooltip, } from '@chakra-ui/react';
import { useCallback, } from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '~/hooks/useWindowSize';

import { Select, chakraComponents } from "chakra-react-select";
import PrimaryButton from '~/components/PrimaryButton';
import { useConnectWallet } from '~/hooks/@global/useConnectWallet';
import { useSwitchChain } from '~/hooks/@global/useSwitchChain';
import useCopyToClipboard from '~/hooks/@global/useCopyToClipboard';
import useCustomToast from '~/hooks/@global/useCustomToast';
import BoxLayout from '~/components/BoxLayout';
import WrapperHeaderMobile from '~/layouts/MasterLayout/WrapperHeaderMobile';
import { useCrawlAgg } from '~/views/CrawlView/hooks/useCrawlAgg';
import ManagerLayout from '~/layouts/ManagerLayout';
import { EVM_AGG } from '~/common/code.helper';

const MAP_CHAIN = EVM_AGG.map((evm) => ({
  value: evm.chainId,
  icon: evm.logo,
  label: <Text
    fontSize={{ base: "14px" }}
    textAlign={"left"}
    padding={{ base: "10px 4px" }}
    color={"#000"}
    fontWeight={400}
  >
    {evm.chainName}
  </Text>,
}))


export const CreateCrawlView = () => {

  const { width, height } = useWindowSize();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const { account } = useConnectWallet();
  const switchChain = useSwitchChain();
  const [, copy] = useCopyToClipboard();

  const {
    isLoading,
    crawl,
    selectChain,
    setSelectChain,
    keySearch,
    setKeySearch,
    txhHash,
    setTxhHash,
  } = useCrawlAgg();

  const customComponents = {
    Option: ({ children, ...props }) => (
      //@ts-ignore
      <chakraComponents.Option {...props}>
        {props.data.icon &&
          <Image src={props.data.icon} h={5} w={5} />
        }
        {children}
      </chakraComponents.Option>
    ),
    SingleValue: ({ children, ...props }) => (
      //@ts-ignore
      <chakraComponents.SingleValue {...props}>
        <HStack spacing={0}>
          {props.data.icon &&
            <Image src={props.data.icon} h={5} w={5} />
          }
          {children}
        </HStack>
      </chakraComponents.SingleValue>
    ),

  };

  const onSwitchChain = useCallback((selectChain: number) => {
    switchChain(selectChain).catch();
  }, [switchChain]);

  const renderButton = useCallback(() => {
    return (
      <PrimaryButton
        color={"#fff"}
        w={{ base: "full", md: "280px" }}
        isLoading={isLoading}
        isDisabled={isLoading || !account}
        onClick={crawl}
      >
        Crawl
      </PrimaryButton>
    )
  }, [account, crawl, isLoading])

  return (
    <ManagerLayout>

      <WrapperHeaderMobile />

      <BoxLayout>

        <Box
          overflow="hidden"
        >

          <Container maxW={'3xl'}>
            <Stack
              as={Box}
              textAlign={'center'}
              spacing={{ base: 4, md: 6 }}
            >

              <Heading
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                lineHeight={'110%'}>
                NFT Aggregator Setting
              </Heading>

              <VStack
                overflow="hidden"
                alignItems={"start"}
                textAlign={"start"}
                spacing={4}
              >

                <Text color={'gray.500'}>
                  1. FEE SHARING FOR NFT CRAWLER
                  <br />
                  - For users who import NFTs through the NFT Aggregator feature (cost 1$ per NFT Collection), you also have the opportunity to earn a % fee from the user's mining fees if they mint your Aggregated NFT Collection.
                </Text>

                <Text color={'gray.500'}>
                  2. POTENTIAL RETROACTIVE AIRDROP FROM NFT AGGREGATOR COLLECTION
                  <br />
                  - Users who mint the NFT Aggregator NFT Collection will have a chance to jump into the retroactive airdrop activity of Chain or Project.
                  <br />
                  - Occasionally, NFTFeed will randomly giveaway for NFT minters based on the NFT collection on NFTFeed DApp.
                </Text>

              </VStack>

            </Stack>

          </Container>

        </Box>

        <Box
          mt="30px"
          w="full"
          p={{ base: '10px', lg: '15px 20px', }}
          bg="white"
          borderRadius="8px"
          border={{ md: "1px solid #ffd3cb" }}
          boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
        >

          <VStack
            w="full"
            alignItems={"start"}
          >

            <Grid
              w="full"
              borderRadius="4px"
              templateColumns="repeat(3, 1fr)"
              gap={4}
            >

              {/* <GridItem
                w='full'
                colSpan={{ base: 3, md: 1, }}
              >

                <VStack
                  w="full"
                  alignItems={"start"}
                >

                  <Text color={'gray.500'}>
                    Txh Hash
                  </Text>

                  <InputGroup size='sm'>
                    <Input
                      color="black.light"
                      borderColor="black.light !important"
                      borderWidth="1px"
                      borderStyle="solid"
                      placeholder="Input Your TxhHash"
                      borderRadius="10px"
                      fontSize="16px"
                      lineHeight="20px"
                      h="40px"
                      w={{ base: "100%", }}
                      bg="#F7F9FA"
                      mb="20px"
                      value={txhHash}
                      onChange={(e) => { setTxhHash(e.target.value || "") }}
                    />
                    <InputRightAddon
                      cursor={{ lg: "pointer" }}
                      h="40px"
                      borderColor="black.light !important"
                      borderWidth="1px"
                      borderStyle="solid"
                      borderRightRadius={"16px"}
                      onClick={() => {
                        try {
                          copy(txhHash);
                          toast.success({
                            title: "Copy Success!",
                          })
                        } catch (error) {

                        }
                      }}
                    >
                      Copy
                    </InputRightAddon>
                  </InputGroup>

                </VStack>

                <PrimaryButton
                  color={"#fff"}
                  w={{ base: "full", md: "280px" }}
                  isLoading={isLoading}
                  isDisabled={isLoading || !account}
                  onClick={() => { }}
                >
                  Test Crawl
                </PrimaryButton>

              </GridItem> */}

              <GridItem
                w='full'
                colSpan={{ base: 3, md: 2, }}
              >

                <SimpleGrid
                  w="full"
                  spacing={4}
                  columns={{ base: 1, md: 1 }}
                >

                  <VStack
                    w="full"
                    alignItems={"start"}
                  >

                    <Text color={'gray.500'}>
                      Chain
                    </Text>

                    <Box
                      w={{ base: "full" }}
                    >
                      <Select
                        isSearchable={false}
                        placeholder="Select ChainID"
                        onChange={e => {
                          setSelectChain(e.value);
                          onSwitchChain(e.value);
                        }}
                        value={MAP_CHAIN.find(i => i.value === selectChain)}
                        options={MAP_CHAIN}
                        components={customComponents}
                        focusBorderColor={`#000`}
                        className="chakra-react-select"
                        classNamePrefix="chakra-react-select"
                      />
                    </Box>

                  </VStack>

                  <VStack
                    w="full"
                    alignItems={"start"}
                  >

                    <Text color={'gray.500'}>
                      NFT Contract
                    </Text>

                    <InputGroup size='sm'>
                      <Input
                        color="black.light"
                        borderColor="black.light !important"
                        borderWidth="1px"
                        borderStyle="solid"
                        placeholder="NFT Contract"
                        borderRadius="10px"
                        fontSize="16px"
                        lineHeight="20px"
                        h="40px"
                        w={{ base: "100%", }}
                        bg="#F7F9FA"
                        mb="20px"
                        value={keySearch}
                        onChange={(e) => { setKeySearch(e.target.value || "") }}
                      />
                      <InputRightAddon
                        cursor={{ lg: "pointer" }}
                        h="40px"
                        borderColor="black.light !important"
                        borderWidth="1px"
                        borderStyle="solid"
                        borderRightRadius={"16px"}
                        onClick={() => {
                          try {
                            copy(keySearch);
                            toast.success({
                              title: "Copy Success!",
                            })
                          } catch (error) {

                          }
                        }}
                      >
                        Copy
                      </InputRightAddon>
                    </InputGroup>

                  </VStack>

                </SimpleGrid>

                <VStack
                  w="full"
                  alignItems={"start"}
                >

                  {renderButton()}

                </VStack>

              </GridItem>


            </Grid>

          </VStack>

        </Box>

      </BoxLayout>

      <Box h="20vh" />

    </ManagerLayout>
  )
}
