import { Contract } from "ethers";
import { useCallback, useState } from "react";
import { configEnv } from "~/@config";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import useCustomToast from "~/hooks/@global/useCustomToast";
import userService from "~/services/user.service";


export const useUpdateTonWallet = () => {

  const { account, } = useConnectWallet();
  const [tonAddress, setTokenAddress] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const toast = useCustomToast();

  const updateTon = useCallback(async (userId: string) => {
    setIsLoading(true);
    try {

      if (account && userId && tonAddress) {

        const res = await userService.updateTonWallet({
          userId,
          evmAddress: account,
          tonWallet: tonAddress,
        })

        toast.success({
          title: "Update TON Wallet Success!",
        });
      }

    } catch (error) {
      setIsLoading(false);
      console.log(`------------------- `);
      console.log(error);
      console.log(`------------------- `);
      toast.handleError(error);
    }
    setIsLoading(false);
  }, [account, tonAddress])

  return {
    isLoading,
    updateTon,
    tonAddress,
    setTokenAddress,
  }
}
