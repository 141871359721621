import {
  Modal, ModalOverlay, ModalContent, ModalHeader,
  ModalCloseButton, ModalBody, ModalFooter, Divider, VStack, Input, Text, HStack, Button, Box, Wrap, WrapItem,
} from "@chakra-ui/react"
import { useCallback, useState } from "react";
import { getRandomColor } from "~/common/code.helper";
import PrimaryButton from "~/components/PrimaryButton"
import useCustomToast from "~/hooks/@global/useCustomToast";
import userService from "~/services/user.service";

export const ModalUpdateMintConfig = (props: {
  onClose?: () => void,
  isOpen: boolean,
  address: string,
  chainId: number,
  name: string
}) => {
  const { onClose, isOpen, address, chainId, name, } = props;

  const toast = useCustomToast();
  const [listTxh, setListTxh] = useState([]);
  const [txhValue, setTxhValue] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const saveMintConfig = useCallback(async () => {
    setIsLoading(true);
    try {
      const body = {
        address: address,
        chainId: chainId,
        txHashs: listTxh,
      }
      console.log({ body });

      const rs = await userService.updateMintConfig(body);

      setListTxh([]);
      toast.show({
        title: 'SUCCESS',
        subTitle: 'Update Mint Config success!',
        type: 'success',
      })
      onClose();
      setIsLoading(false);

    } catch (error) {
      toast.handleError(error);
      setIsLoading(false);
    }
  }, [address, chainId, listTxh, onClose,])

  const renderContent = useCallback(() => {
    return (
      <VStack
        pt="20px"
        w="full"
        spacing={4}
      >

        <HStack
          w="full"
          justifyContent={"space-between"}
        >

          <Text
            fontWeight={500}
            fontSize={"13px"}
            textAlign={"start"}
            alignSelf={"center"}
          >
            TxHash
          </Text>

          <Button
            onClick={() => {
              setListTxh([]);
              setTxhValue(null);
            }}
            fontSize={"13px"}
            p="5px 10px"
            height={"auto"}
          >
            Clear All
          </Button>

        </HStack>

        <HStack w="full">
          <Input
            value={txhValue || ""}
            onChange={(e) => {
              setTxhValue(e.target.value.trim())
            }}
          />
          <Button
            onClick={() => {
              if (txhValue) {
                setListTxh([...listTxh, txhValue]);
              }
              setTxhValue(null);
            }}
          >
            Add
          </Button>
        </HStack>

        <Wrap>
          {listTxh.map((item, idx) => {
            return (
              <WrapItem
                key={idx}
                p="5px 10px"
                borderWidth={"1px"}
                borderStyle={"solid"}
                borderColor={getRandomColor()}
                borderRadius={"4px"}
              >
                <Text color={"#000"} fontSize={"13px"} >{item}</Text>
              </WrapItem>
            )
          })}
        </Wrap>


      </VStack>
    )
  }, [listTxh, txhValue])

  return (
    <Modal
      isCentered
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
    >
      <ModalOverlay />
      <ModalContent maxWidth="642px" w="100%" borderRadius="12px" mx="15px">

        <ModalHeader
          fontSize="18px"
          fontWeight="500"
          letterSpacing="-0.5px"
          textAlign={"center"}
        >
          {`Update Mint Config`}
          <br />
          {`[ ${name} ]`}
        </ModalHeader>

        <ModalCloseButton bg="#FFF" />

        <Divider />

        <ModalBody bg="#FFFFFF" px="15px" borderRadius="12px">

          {renderContent()}

        </ModalBody>

        <ModalFooter>
          <PrimaryButton
            variant="ghost"
            onClick={saveMintConfig}
            isLoading={isLoading}
            isDisabled={isLoading}
          >
            Update
          </PrimaryButton>
        </ModalFooter>

      </ModalContent>
    </Modal>
  )
}
