import { Box, SimpleGrid, Text, VStack, Image, Heading, HStack, Container, Stack, Tooltip, Button, } from '@chakra-ui/react';
import MainLayout from "~/layouts/MainLayout"
import WrapperHeaderMobile from "../../layouts/MasterLayout/WrapperHeaderMobile"
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '~/hooks/useWindowSize';

import { EVM_CHAIN_LIST } from '~/@config/chain-list';
import useCustomToast from '~/hooks/@global/useCustomToast';
import BoxLayout from '~/components/BoxLayout';
import PaginationComponent from '~/components/Pagination';
import { useListAgg } from './hooks/useListAgg';
import { usePagination } from "@ajna/pagination"
import { MAIN_ROUTERS, MANAGER_ROUTERS } from '~/routes/routes';
import { getRandomColor } from '~/common/code.helper';
import { NftCollection } from '~/dto/nft-project.dto';
import { IoPlayCircleOutline } from 'react-icons/io5';
import NoImg from "~/assets/images/no_img.png"
import IcVerifyTw from "~/assets/icons/ic_verify_tw.svg"
import CountUp from 'react-countup';
import IcRibbon from "~/assets/icons/ribbon.png"

const CrawlItem = (props: {
  item: NftCollection,
}) => {

  const { width, height } = useWindowSize();
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const {
    address, image, chainId, qtyMinted,
    price,
    logo,
    type,
    name,
    endTime,
    isVerifyTwitter,
    banner,
  } = props.item;
  const isEnded = new Date(endTime).getTime() < new Date().getTime();
  const { logo: logoChain, nativeCurrency } = EVM_CHAIN_LIST[chainId] || { chainName: "", logo: "", dislayName: "", nativeCurrency: { symbol: "" } }

  useEffect(() => {
    videoRef?.current?.load()
  }, [])


  const renderImage = useMemo(() => {
    return (
      <VStack
        w="full"
        position={"absolute"}
        bottom={-5}
      >

        {
          image.includes(".mp4")
            ?
            <Box
              height="inherit"
              position={"relative"}
              h={{ base: "40px", }}
              w="40px"
              p={{ base: "3px", md: "0px" }}
              borderRadius={{ base: "8px", md: "24px" }}
            >

              <Box
                w="100%"
                height="inherit"
              >
                <video
                  ref={videoRef}
                  //@ts-ignore
                  playsInline="playsInline"
                  //@ts-ignore
                  muted="muted"
                  style={{
                    borderRadius: "24px",
                    width: "100%",
                    height: "inherit",
                    objectFit: "cover"
                  }}
                  controls={false}
                >
                  <source src={image} type="video/mp4" />
                </video>
              </Box>

              <Box
                bg="#fff"
                borderRadius={"8px"}
                bottom={1}
                left={2}
                position={"absolute"}
                cursor={{ lg: 'pointer' }}
                onClick={(e) => {
                  e.stopPropagation();
                  videoRef.current.play();
                }}
              >
                <IoPlayCircleOutline size={"10px"} />
              </Box>
            </Box>
            :
            <Image
              background="rgba(236, 236, 236)"
              borderRadius={"24px"}
              src={image}
              h={{ base: "40px" }}
              w="40px"
              p={{ base: "3px", md: "0px" }}
              objectFit={"contain"}
              fallbackSrc={NoImg}
              loading="lazy"
            />
        }

      </VStack>
    )
  }, [image])

  return (
    <VStack
      w="full"
      spacing={2}
    >

      <VStack
        w="full"
        position={"relative"}
      >
        <VStack w="full" background="rgba(236, 236, 236)" borderRadius={"8px"}>
          <Image
            src={banner || logo}
            h="150px"
            objectFit={"cover"}
            borderRadius={"8px"}
          />
        </VStack>

        <Tooltip
          label="NFT Aggregator"
        >
          <Box
            position={"absolute"}
            cursor={{ lg: "pointer" }}
            top={-1}
            left={-2}
          >
            <Image src={IcRibbon} w="80px" />
          </Box>
        </Tooltip>

        {renderImage}

      </VStack>

      <VStack
        mt="10px"
        w="full"
        spacing={2}
        alignItems={"start"}
        px="10px"
      >

        <HStack>
          <Text
            fontWeight="600"
            fontSize={{ base: "13px", md: "16px" }}
            lineHeight={{ base: "18px", md: "24px" }}
            textAlign={{ base: "left" }}
            color="black"
            noOfLines={1}
          >
            {name.length > 30 ? name.slice(0, 30).concat("...") : name}
          </Text>

          {isVerifyTwitter &&
            <Image src={IcVerifyTw} w="18px" h="18px" />
          }

        </HStack>

        <VStack
          w="full"
        >
          <Text
            className={"textPrimary"}
            fontWeight={600}
            fontSize={{ base: "13px", md: "16px" }}
          >
            <>
              <CountUp end={qtyMinted} />
              &nbsp;mints
            </>
          </Text>
        </VStack>

        <SimpleGrid
          pt={{ base: "0px", md: "10px" }}
          justifyContent={"space-between"}
          w="full"
          spacing={1}
          columns={{ base: 1, md: 3 }}
        >

          {width <= 500 ?
            <HStack w="full" justifyContent={"space-between"}>
              <Text
                fontSize={{ base: "13px", md: "13px" }}
                color="black.light"
                fontWeight="600"
                textAlign={"left"}
                alignSelf={"center"}
              >
                {isEnded ? `Ended` : "Live"}
              </Text>
              <Image
                src={logoChain}
                w="25px"
                h="25px"
                bg="#FFFBEA"
                p="1px"
                borderRadius={"10px"}
                border="1px solid #FFFBEA"
              />
            </HStack>
            :
            <Text
              fontSize={{ base: "13px", md: "13px" }}
              color="black.light"
              fontWeight="600"
              textAlign={"left"}
            >
              {isEnded ? `Ended` : "Live"}
            </Text>
          }

          <Box
            bg="#ee3824"
            borderRadius="6px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            p="3px 10px"
            w="full"
          >
            <Text
              fontSize={{ base: "15px", }}
              lineHeight="16px"
              fontWeight="600"
              color="#fff"
              textAlign={"center"}
            >
              {price ? `${price.toString()} ${nativeCurrency.symbol}` : "Free"}
            </Text>
          </Box>

          {width > 500 &&
            <HStack justifySelf={"end"}>
              <Image
                src={logoChain}
                w="20px"
                h="20px"
                bg="#FFFBEA"
                p="1px"
                borderRadius={"10px"}
                border="1px solid #FFFBEA"
              />
            </HStack>
          }

        </SimpleGrid>

      </VStack>

    </VStack>
  )
}

export const CrawlView = () => {

  const { width, height } = useWindowSize();
  const toast = useCustomToast();
  const navigate = useNavigate();

  const {
    isLoading,
    listCrawl,
    total,
    loadListCrawl,
  } = useListAgg();

  useEffect(() => { loadListCrawl(1) }, [loadListCrawl]);

  const { pages, pagesCount, currentPage, setCurrentPage } = usePagination({
    total,
    limits: {
      outer: 2,
      inner: 2,
    },
    initialState: {
      pageSize: 1,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handlePageChange = useCallback(
    (nextPage: number): void => {
      // -> request new data using the page number
      setCurrentPage(nextPage)
      // console.log('request new data with ->', nextPage)
      loadListCrawl(nextPage);
    },
    [setCurrentPage,],
  )


  return (
    <MainLayout>

      <WrapperHeaderMobile />

      <BoxLayout>

        <Box
          overflow="hidden"
        >

          <Container maxW={'3xl'}>
            <Stack
              as={Box}
              textAlign={'center'}
              spacing={{ base: 4, md: 6 }}
            >

              <Heading
                fontWeight={600}
                fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
                lineHeight={'110%'}>
                NFT Aggregator Collection
              </Heading>


              <VStack
                overflow="hidden"
                textAlign={"start"}
                spacing={4}
              >

                <Text color={'gray.500'}>
                  The NFT Aggregator is a feature that lets users easily find and collect their favorite NFTs from a variety of sources, covering 14 different blockchain networks that NFTFeed supports.
                </Text>

                <Button
                  colorScheme={'green'}
                  rounded={'full'}
                  px={6}
                  bg="yellow.primary !important"
                  color="black.1d"
                  w={{ base: "full", md: "fit-content" }}
                  fontSize={{ base: "13px", md: "16px" }}
                  onClick={() => {
                    navigate(MANAGER_ROUTERS.CREATE_AGGREGATOR)
                  }}
                >
                  Create NFT Aggregator
                </Button>

              </VStack>

            </Stack>

          </Container>

        </Box>

        <VStack w="full" pt="30px" spacing={6}>

          <SimpleGrid
            w="full"
            spacing={{ base: 2, md: 4 }}
            columns={{ base: 2, md: 2, lg: 4, '3xl': 5 }}
          >

            {listCrawl.map((item, idx) => {

              return (
                <Box
                  position={"relative"}
                  key={idx}
                >

                  <Box
                    h="300px"
                    bg="#fff"
                    borderWidth="2px"
                    borderStyle={"solid"}
                    borderColor={getRandomColor()}
                    borderRadius={"8px"}
                    transition="all 500ms ease-in-out"
                  />

                  <VStack
                    h="300px"
                    bottom={0}
                    position={"absolute"}
                    w="full"
                    p={{ base: "5px", }}
                    bg="white"
                    borderRadius="8px"
                    border={{ base: "2px solid #ffd3cb" }}
                    boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
                    cursor={{ lg: "pointer" }}
                    onClick={() => {
                      navigate(`${MAIN_ROUTERS.NFT_COLLECTION}/${item.chainId}/${item.address}`)
                    }}
                    transition="all 500ms ease-in-out"
                    _hover={{
                      transform: "translate(-10px, -10px)"
                    }}
                  >

                    <CrawlItem
                      item={item}
                    />

                  </VStack>

                </Box>
              )
            })
            }

          </SimpleGrid>

          {!isLoading && listCrawl.length > 48 &&
            <PaginationComponent
              pagesCount={pagesCount}
              currentPage={currentPage}
              isDisabled={isLoading}
              onPageChange={handlePageChange}
              pages={pages}
            />
          }

        </VStack>

      </BoxLayout>

      <Box h="20vh" />

    </MainLayout>
  )
}
