import { VStack, HStack, Text, Box, SimpleGrid } from "@chakra-ui/react"
import { useState } from "react";
import { getRandomColor } from "~/common/code.helper"
import { MilestoneCheckinBody, MilestoneCheckinModal } from "./modal/MilestoneCheckinModal";
import { MilestoneQuestModal } from "./modal/MilestoneQuestModal";
import { MilestoneMintModal } from "./modal/MilestoneMintModal";


const DATA = [
  {
    title: "Check-in",
    value: 1,
  },
  {
    title: "Quest",
    value: 2,
  },
  {
    title: "Mints",
    value: 3,
  },
]

export const MilestoneMissions = () => {

  const [isOpenCheckin, setIsOpenCheckin] = useState(false);
  const [isOpenQuest, setIsOpenQuest] = useState(false);

  const [selectModal, setSelectModal] = useState(null);


  return (
    <VStack
      w="full"
      p={{ base: "10px", md: "20px" }}
      bg="#fff"
      borderRadius="20px"
      border={{ base: "1px solid #000" }}
      boxShadow="0px 10px 0px 0px #ffd3cb"
      alignItems={"start"}
    >

      <Text
        color="#000"
        fontWeight={700}
        textAlign="center"
        fontSize="20px"
        lineHeight="24px"
        bg="#ffd3cb9e"
        borderRadius={"8px"}
        px="4px"
        mb="10px"
      >
        Check-in Milestone
      </Text>

      <MilestoneCheckinBody />

      {/* <SimpleGrid
        w='full'
        spacing={4}
        columns={{ base: 1, md: 2, lg: 5 }}
      >

        {DATA.map((item, idx) => {

          return (
            <Box
              position={"relative"}
              key={idx}
            >

              <Box
                h="200px"
                bg="#fff"
                borderWidth="2px"
                borderStyle={"solid"}
                borderColor={getRandomColor()}
                borderRadius={"8px"}
                transition="all 500ms ease-in-out"
              />

              <VStack
                h="200px"
                bottom={0}
                position={"absolute"}
                w="full"
                p={{ base: "5px", }}
                bg="white"
                borderRadius="8px"
                border={{ base: "2px solid #ffd3cb" }}
                boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
                transition="all 500ms ease-in-out"
                _hover={{
                  transform: "translate(-10px, -10px)"
                }}
                cursor={{ lg: "pointer" }}
                onClick={() => {
                  setSelectModal(item.value)
                }}
                justifyContent={"center"}
              >

                <Text
                  color="#000"
                  fontWeight={700}
                  textAlign="center"
                  fontSize="20px"
                  lineHeight="24px"
                  bg="#ffd3cb9e"
                  borderRadius={"8px"}
                  px="4px"
                >
                  {item.title}
                </Text>

              </VStack>

            </Box>
          )
        })
        }

      </SimpleGrid> */}


      <MilestoneCheckinModal
        isOpen={1 === selectModal}
        onClose={() => { setSelectModal(null) }}
      />

      <MilestoneQuestModal
        isOpen={2 === selectModal}
        onClose={() => { setSelectModal(null) }}
      />

      <MilestoneMintModal
        isOpen={3 === selectModal}
        onClose={() => { setSelectModal(null) }}
      />

    </VStack>
  )
}
