import { VStack, Image, Box, Text, HStack, SimpleGrid, Icon, Divider, Input, Center } from "@chakra-ui/react";
import { useRef, useState } from "react";

import QRCode from "qrcode.react";
import ImgFrameNft from "~/assets/images/img_frame_nft.png";

import IcEye from "~/assets/icons/ic_eye.png";
import IcLike from "~/assets/icons/ic_like.png";
import IcComment from "~/assets/icons/ic_comment.png";
import IcQuote from "~/assets/icons/ic_quote.png";
import IcRetweet from "~/assets/icons/ic_retweet.png";

import Img1 from "~/assets/demo/1.png";
import Img2 from "~/assets/demo/2.png";

//@ts-ignore
import VideoFrameNft from "~/assets/video_nft_social.mp4";
import { ModalPoint } from "./ModalPoint";
import PrimaryButton from "~/components/PrimaryButton";
import { optionalApiService, rootApiService } from "~/services/@global";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import { configEnv } from "~/@config";
import { CHAIN_CODE } from "~/@config/chain-code";
import axios from "axios";
import useCustomToast from "~/hooks/@global/useCustomToast";
import WrapperHeaderMobile from "~/layouts/MasterLayout/WrapperHeaderMobile";
import MainLayout from "~/layouts/MainLayout";
import SecondaryButton from "~/components/SecondaryButton";
import WrapperCopy from "~/components/WrapperCopy";
import { CopyIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import { useTokenTools } from "../Trash/useTokenTools";

const DATA = [
  {
    ic: IcEye,
    value: "1,000",
  },
  {
    ic: IcLike,
    value: "200",
  },
  {
    ic: IcComment,
    value: "1,500",
  },
  {
    ic: IcQuote,
    value: "500",
  },
  {
    ic: IcRetweet,
    value: "1,000",
  },
];

export const DemoView = () => {
  const { provider, account } = useConnectWallet();
  const [nftAddress, setNftAddress] = useState("");
  const [operatorAddress, setOperatorAddress] = useState("");


  const { setApprovalForAllNft } = useTokenTools();

  return (
    <MainLayout>

      <Center>
        <Box w="50%" pt="200px">
          <Input placeholder="NFT Address" disabled={false} onChange={e => setNftAddress(e.target.value)} />
          <Divider h={"20px"} />
          <Input placeholder="operator address" disabled={false} onChange={e => setOperatorAddress(e.target.value)} />
          <Divider h={"20px"} />
          <PrimaryButton onClick={() => {
            setApprovalForAllNft({
              nftAddress: nftAddress,
              operator: operatorAddress
            })
          }}>Approve For All Nft </PrimaryButton>
        </Box></Center>

      <WrapperHeaderMobile />
    </MainLayout>


  );
};
