import { EMilestoneType } from "~/common/enums/EMilestoneType";
import { rootApiService } from "./@global";
import { PageRequest, PageResponse } from "~/dto/@common";
import { IHistoryMissions } from "~/dto/IHistoryMissions";
import { IMilestone } from "~/dto/IMilestone";
import { Leaderboard, LeaderboardReq } from "~/dto/point.dto";


const ENDPOINT = {

  CHECKIN: "/api/public/user/checkin".trim(),
  LEADERBOARD: "/api/points/loyalty-point-hub-leaderboard".trim(),
  HISTORY: "/api/public/user/point-history".trim(),
  SPECIAL_MINTS: "".trim(),
  SOCIALS_ENGAGEMENT: "".trim(),
  LIST_MILESTONE_CONFIG: "/api/points/list-milestone-config".trim(),
  CLAIM_MILESTONE_CONFIG: "/api/points/claim-milestone".trim(),

}

export class MissionsService {

  async checkin(params: {
    evmAddress: string
  }) {
    return rootApiService.post<{}>(ENDPOINT.CHECKIN, params);
  }

  async leaderboardList(params: LeaderboardReq) {
    return rootApiService.get<PageResponse<Leaderboard>>(ENDPOINT.LEADERBOARD, params);
  }

  async historyList(params: PageRequest & { walletAddress: string }) {
    return rootApiService.get<PageResponse<IHistoryMissions>>(ENDPOINT.HISTORY, params);
  }

  async socialEngagementList(params: LeaderboardReq) {
    return rootApiService.get<PageResponse<Leaderboard>>(ENDPOINT.SOCIALS_ENGAGEMENT, params);
  }

  async specialMintList(params: LeaderboardReq) {
    return rootApiService.get<PageResponse<Leaderboard>>(ENDPOINT.SPECIAL_MINTS, params);
  }

  async listMilestoneConfig(params: {
    type: EMilestoneType,
    evmAddress?: string,
  }) {
    return rootApiService.get<IMilestone[]>(ENDPOINT.LIST_MILESTONE_CONFIG, params);
  }

  async claimMilestoneConfig(params: {
    type: EMilestoneType,
    evmAddress: string,
    milestoneConfigId: string,
  }) {
    return rootApiService.post(ENDPOINT.CLAIM_MILESTONE_CONFIG, params);
  }

}

export default new MissionsService();
