export enum EPointHistoryType {
  MINT_DIRECT = 1,
  MINT_STRING,
  SOCIAL_TASK,
  SOCIAL_QUEST,
  DAILY_QUEST,
  SHARE,
  DAILY_CHECKIN,
  SSO_CAMPAIGN,
  TWITTER_LINK,
  NFT_PARTNER_MINT,
  MILESTONE_CHECKIN,
  SNAPSHOT,
  MIRROR_SNAPSHOT,
}

export const ETextPointHistoryType = {

  [EPointHistoryType.MINT_DIRECT]: {
    text: "Mint Direct",
    value: EPointHistoryType.MINT_DIRECT,
  },
  [EPointHistoryType.MINT_STRING]: {
    text: "Mint String",
    value: EPointHistoryType.MINT_STRING,
  },
  [EPointHistoryType.SOCIAL_TASK]: {
    text: "Social Task",
    value: EPointHistoryType.SOCIAL_TASK,
  },
  [EPointHistoryType.SOCIAL_QUEST]: {
    text: "Social Quest",
    value: EPointHistoryType.SOCIAL_QUEST,
  },
  [EPointHistoryType.DAILY_QUEST]: {
    text: "Daily Quest",
    value: EPointHistoryType.DAILY_QUEST,
  },
  [EPointHistoryType.SHARE]: {
    text: "Share",
    value: EPointHistoryType.SHARE,
  },
  [EPointHistoryType.DAILY_CHECKIN]: {
    text: "Daily Check-in",
    value: EPointHistoryType.DAILY_CHECKIN,
  },
  [EPointHistoryType.SSO_CAMPAIGN]: {
    text: "SSO Campaign",
    value: EPointHistoryType.SSO_CAMPAIGN,
  },
  [EPointHistoryType.TWITTER_LINK]: {
    text: "Twitter Link",
    value: EPointHistoryType.TWITTER_LINK,
  },
  [EPointHistoryType.NFT_PARTNER_MINT]: {
    text: "Mint Platform",
    value: EPointHistoryType.NFT_PARTNER_MINT,
  },
  [EPointHistoryType.MILESTONE_CHECKIN]: {
    text: "Milestone Check-in",
    value: EPointHistoryType.MILESTONE_CHECKIN,
  },
  [EPointHistoryType.SNAPSHOT]: {
    text: "NFT + WSHAKIE Snapshot",
    value: EPointHistoryType.SNAPSHOT,
  },
  [EPointHistoryType.MIRROR_SNAPSHOT]: {
    text: "Mirror Snapshot",
    value: EPointHistoryType.MIRROR_SNAPSHOT,
  },
}
