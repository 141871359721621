import { useCallback, useEffect, useState } from "react"
import { PageResponse } from "~/dto/@common"
import { Leaderboard } from "~/dto/point.dto"
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import useCustomToast from "~/hooks/@global/useCustomToast";
import missionsService from "~/services/missions.service";


export const useLeaderboardMission = () => {

  const toast = useCustomToast();
  const { account } = useConnectWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [keySearch, setKeySearch] = useState("");

  const [response, setResponse] = useState<PageResponse<Leaderboard>>({
    data: [],
    total: 0
  });


  const loadData = useCallback(async () => {
    setIsLoading(true);
    try {
      const { data, total } = await missionsService.leaderboardList({
        pageIndex: 1,
        pageSize: 50,
      });

      setResponse({
        data,
        total
      })
    } catch (error) {

    }
    setIsLoading(false);

  }, [keySearch]);

  useEffect(() => { loadData() }, [loadData])

  return {
    isLoading,
    keySearch,
    setKeySearch,
    ...response,
  }
}
