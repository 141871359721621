import { Box, Grid, GridItem, HStack, Image, Text, Tooltip, VStack } from "@chakra-ui/react"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { IoPlayCircleOutline } from "react-icons/io5"
import { useNavigate } from "react-router-dom"
import { CHAIN_CODE } from "~/@config/chain-code"
import { EVM_CHAIN_LIST } from "~/@config/chain-list"
import IcVerifyTw from "~/assets/icons/ic_verify_tw.svg"
import IcRibbon from "~/assets/icons/ribbon.png"
import IconETH from "~/assets/images/eth.png"
import NoImg from "~/assets/images/no_img.png"
import { EVM_TESTNETS } from "~/common/code.helper"
import { ECollectionType } from "~/common/enums/ECollectionType"
import DiscordICon from '~/components/Icons/Discord'
import { NftCollection } from "~/dto/nft-project.dto"
import useWindowSize from "~/hooks/useWindowSize"
import { MANAGER_ROUTERS } from "~/routes/routes"
import { ButtonMints } from "~/views/FreeMintView/CollectionTrend/ButtonMints"
import { TagBlue, TagRed } from "~/views/Manager/Tags"
import GlobalIcon from "../Icons/Global"
import TwitterIcon from "../Icons/Twitter"

export interface ITableProps extends NftCollection {
  layout?: 'grid' | 'table'
  isPopolar?: boolean
  handleClick: () => void,
  isHardCode: boolean
  isEnded: boolean
  logo: any
}

export const CardItemGrid = (props: ITableProps) => {
  const videoRef = useRef(null);
  const { width } = useWindowSize();
  const {
    address, image, chainId, qtyMinted,
    isEnded,
    twitter = "", website = "", discord = "", price,
    layout, isPopolar = false,
    isHardCode,
    handleClick,
    logo,
    type,
    mintPoint,
  } = props;
  const navigate = useNavigate();

  useEffect(() => {
    videoRef?.current?.load()
  }, [])

  const renderSocsialIcon = useCallback(() => {
    if (width <= 500) {
      return null;
    }
    return (
      <HStack
        mt={layout === "grid" ? "0px" : "12px"}
        cursor={{ base: "none", lg: "pointer" }}
        mb={"4px"}
      >
        {website && <GlobalIcon onClick={() => window.open(website)} />}
        {twitter && <TwitterIcon onClick={() => window.open(twitter)} />}
        {discord && <DiscordICon onClick={() => window.open(discord)} />}

      </HStack >
    )
  }, [discord, layout, twitter, website, width])

  const icPrice = useMemo(() => {
    if (
      chainId === CHAIN_CODE.BSC_MAINNET || chainId === CHAIN_CODE.BSC_TESTNET
      || chainId === CHAIN_CODE.OPBNB
      || chainId === CHAIN_CODE.ZETACHAIN_TESTNET || chainId === CHAIN_CODE.ZETACHAIN_MAINNET
    ) {
      return EVM_CHAIN_LIST[chainId].logo;
    }
    return IconETH;
  }, [chainId,])

  const renderImage = useCallback(() => {

    return (
      <VStack
        alignItems={"start"}
        h="64px"
        w="100px"
      >

        {image.includes(".mp4") ?
          <Box
            w="100%"
            height="inherit"
            position={"relative"}
          >

            <Box
              w="100%"
              height="inherit"
            >
              <video
                ref={videoRef}
                //@ts-ignore
                playsInline="playsInline"
                //@ts-ignore
                muted="muted"
                style={{
                  borderRadius: "8px",
                  width: "100%",
                  height: "inherit",
                  objectFit: "cover"
                }}
                controls={false}
              // poster={NoImg}
              >
                <source src={image} type="video/mp4" />
              </video>
            </Box>

            <Box
              bg="#fff"
              borderRadius={"8px"}
              bottom={1}
              left={1}
              position={"absolute"}
              cursor={{ lg: 'pointer' }}
              onClick={(e) => {
                e.stopPropagation();
                videoRef.current.play();
              }}
            >
              <IoPlayCircleOutline size={"16px"} />
            </Box>
          </Box>
          :
          <Image
            src={image}
            h="inherit"
            w="full"
            borderRadius={"8px"}
            objectFit={"cover"}
            loading="lazy"
            fallbackSrc={NoImg}
          />
        }

      </VStack>
    )
  }, [image])

  return (
    <Box
      w='full'
      position={"relative"}
      boxShadow="5px 5px 0px 0px #f8a496"
      borderRadius="4px"
      border={{ base: "1px solid #000" }}
    >

      <Grid
        w="full"
        bg="#FFFFFF"
        borderRadius="4px"
        p={{ base: '0', lg: '10px 5px', }}
        gap={2}
        templateColumns="repeat(12, 1fr)"
      >

        <GridItem
          w='full'
          colSpan={{ md: 6 }}
          alignSelf={"center"}
          cursor={"pointer"}
          onClick={handleClick}
        >

          <HStack
            w="full"
            spacing={2}
          >

            {renderImage()}

            <VStack
              w="full"
              spacing={1}
              alignItems={"start"}
            >

              <HStack
                alignItems={"center"}
                w="full"
              >
                <Text
                  fontWeight="600"
                  fontSize={{ base: "16px" }}
                  lineHeight={"normal"}
                  textAlign="left"
                  color="black"
                  _hover={{
                    textDecorationLine: "underline"
                  }}
                  noOfLines={1}
                  maxW={"230px"}
                >
                  {props.name && props.name.length > 40 ? props.name.slice(0, 40).concat("...") : props.name}
                </Text>

                {props.isVerifyTwitter &&
                  <Image src={IcVerifyTw} w="15px" h="15px" />
                }

              </HStack>

              {price &&
                <HStack
                  mt="0px !important"
                  w="full"
                >

                  <Text
                    fontSize="13px"
                    fontWeight="500"
                    lineHeight={"normal"}
                  >
                    Price:
                  </Text>

                  <HStack spacing={"3px"}>
                    <Text
                      fontSize="16px"
                      color="black.light"
                      fontWeight="600"
                      lineHeight={"normal"}
                    >
                      {price}
                    </Text>
                    <Image
                      src={icPrice}
                      w="20px"
                      h="20px"
                      bg="#FFFBEA"
                      p="1px"
                      borderRadius={"10px"}
                      border="1px solid #FFFBEA"
                    />
                  </HStack>

                </HStack>
              }

              {isPopolar && !price &&
                <HStack
                  mt="0px !important"
                  w="full"
                >

                  <Text
                    fontSize={{ base: "13px", }}
                    fontWeight="500"
                    lineHeight={"normal"}
                  >
                    Price:
                  </Text>

                  <Text
                    fontSize={{ base: "13px", }}
                    color="black.light"
                    fontWeight="600"
                    lineHeight={"normal"}
                  >
                    Free
                  </Text>

                </HStack>
              }

              {!EVM_TESTNETS.includes(chainId) && props.priceSymbol !== "TON" &&
                <Box
                  bg="#ffd3cb9e"
                  borderRadius={"8px"}
                  p="3px 5px"
                >
                  <Text
                    color="red"
                    fontSize="14px"
                    fontWeight="700"
                    lineHeight={"normal"}
                  >
                    +{mintPoint} Points
                  </Text>
                </Box>
              }

            </VStack>

          </HStack>

        </GridItem>

        <GridItem
          w='full'
          colSpan={{ md: 4, }}
          alignSelf={"center"}
        >

          <VStack>
            {renderSocsialIcon()}

            <HStack mt={"0px !important"}>

              {isHardCode ?
                <>
                  <TagBlue value={"Live"} />
                  <Image
                    src={"https://dd.dexscreener.com/ds-data/chains/arbitrum.png"}
                    w="25px"
                    h="25px"
                    bg="#FFFBEA"
                    p="1px"
                    borderRadius={"10px"}
                    border="1px solid #FFFBEA"
                  />
                </>
                :
                <>
                  {isEnded ?
                    <TagRed value={"Ended"} />
                    :
                    <TagBlue value={"Live"} />
                  }
                  <Image
                    src={props.priceSymbol === "TON" ? "https://s3.coinmarketcap.com/static/img/portraits/6304d4f7dcf54d0fb59743ba.png" : logo}
                    w="25px"
                    h="25px"
                    bg="#FFFBEA"
                    p="1px"
                    borderRadius={"10px"}
                    border="1px solid #FFFBEA"
                  />
                </>
              }

            </HStack>
          </VStack>

        </GridItem>

        <GridItem
          w='full'
          colSpan={{ md: 2, }}
          alignSelf={"center"}
        >
          <VStack
            w="full"
            alignItems={"end"}
          >

            <ButtonMints
              address={address}
              qtyMinted={qtyMinted}
              layout={layout}
              chainId={chainId}
            />

          </VStack>
        </GridItem>

      </Grid>

      {type === ECollectionType.CRAWL &&
        <Tooltip
          label="NFT Aggregator"
        >
          <Box
            position={"absolute"}
            cursor={{ lg: "pointer" }}
            top={0}
            left={0}
            onClick={(e) => {
              e?.stopPropagation();
              navigate(`${MANAGER_ROUTERS.AGGREGATOR}`);
            }}
          >
            <Image src={IcRibbon} w="55px" />
          </Box>
        </Tooltip>
      }

    </Box>

  )

}
