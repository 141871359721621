import { SVGProps } from 'react'

const DiscordIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.058 4.441A14.153 14.153 0 0 0 12.5 3.333a.075.075 0 0 0-.058.025c-.15.275-.325.633-.442.908-1.326-.2-2.674-.2-4 0a8.356 8.356 0 0 0-.45-.908c-.008-.017-.033-.025-.058-.025-1.25.217-2.442.592-3.559 1.108-.008 0-.016.009-.025.017-2.266 3.392-2.891 6.692-2.583 9.958 0 .017.008.034.025.042 1.5 1.1 2.942 1.767 4.367 2.208.025.009.05 0 .058-.016.333-.459.633-.942.892-1.45.016-.034 0-.067-.034-.075-.475-.184-.925-.4-1.366-.65-.034-.017-.034-.067-.009-.092.092-.067.184-.142.275-.208a.051.051 0 0 1 .059-.009c2.866 1.309 5.958 1.309 8.791 0a.051.051 0 0 1 .059.009c.091.075.183.141.275.216.033.025.033.075-.009.092a8.926 8.926 0 0 1-1.366.65c-.034.008-.042.05-.034.075.267.508.567.992.892 1.45.025.008.05.017.075.008a14.419 14.419 0 0 0 4.375-2.208.046.046 0 0 0 .025-.042c.367-3.775-.608-7.05-2.583-9.958-.009-.008-.017-.017-.034-.017ZM7.1 12.425c-.858 0-1.575-.792-1.575-1.767 0-.975.7-1.767 1.575-1.767.883 0 1.583.8 1.575 1.767 0 .975-.7 1.767-1.575 1.767Zm5.808 0c-.858 0-1.575-.792-1.575-1.767 0-.975.7-1.767 1.575-1.767.884 0 1.584.8 1.575 1.767 0 .975-.691 1.767-1.575 1.767Z"
      fill="#5F6774"
    />
  </svg>
)

export default DiscordIcon
