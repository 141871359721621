import { VStack, HStack, Text } from "@chakra-ui/react"
import { CiCircleCheck } from "react-icons/ci"

export const ComingsoonMissions = () => {

  return (
    <VStack
      w="full"
      p="20px"
      bg="#fff"
      borderRadius="20px"
      border={{ base: "1px solid #000" }}
      boxShadow="0px 10px 0px 0px #ffd3cb"
      alignItems={"start"}
    >

      <Text
        color="#000"
        fontWeight={700}
        textAlign="center"
        fontSize="20px"
        lineHeight="24px"
        bg="#ffd3cb9e"
        borderRadius={"8px"}
        px="4px"
        mb="10px"
      >
        Extra Points Missions
      </Text>


      <Text
        color="#000"
        fontWeight={500}
        textAlign="center"
        fontSize="36px"
        lineHeight="44px"
        alignSelf={"center"}
        py="25px"
      >
        COMING SOON...
      </Text>


    </VStack>
  )
}
