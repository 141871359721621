import PrimaryButton from "~/components/PrimaryButton";
import { MintConfig } from "~/dto/nft-project.dto";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import { useCrawlMintNft } from "../hooks/useCrawlMintNft";
import { ModalLoadingMint } from "~/components/ModalLoadingMint";

export const MintCrawlButton = (props: {
  address: string,
  chainId: number,
  mintConfig: MintConfig
  openVerfiy: () => void
  priceSymbol: string
}) => {
  const { address, chainId, mintConfig, openVerfiy, priceSymbol } = props;
  const { account, userInfo, chainId: chainIdActive } = useConnectWallet();

  const { isLoading, mintNft } = useCrawlMintNft({
    address: address,
    chainId: chainId,
    mintConfig,
  });

  const handleClick = async () => {
    mintNft(`x${mintConfig.pointFactor * mintConfig.qty}`);

    // if (account && userInfo && (!userInfo.status || userInfo.status < 2)) {
    //   openVerfiy();
    // } else {
    //   mintNft(`x${mintConfig.pointFactor * mintConfig.qty}`);
    // }
  }

  return (
    <>
      <PrimaryButton
        w={{ base: "full", }}
        borderRadius={"12px"}
        isDisabled={isLoading || !account || chainId !== chainIdActive}
        isLoading={isLoading}
        onClick={handleClick}
      >
        {mintConfig.value > 0
          ? `Mint ${mintConfig.qty} for ${mintConfig.value} ${priceSymbol}`
          : `Free Mint for ${mintConfig.qty} NFT`
        }
      </PrimaryButton>

      <ModalLoadingMint
        isOpen={isLoading}
      />

    </>
  )
}
