import { VStack, Box, Text, SimpleGrid, HStack, Image } from "@chakra-ui/react"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { useConnectWallet } from "~/hooks/@global/useConnectWallet"
import useCustomToast from "~/hooks/@global/useCustomToast"
import useWindowSize from "~/hooks/useWindowSize"
import { formatMoney } from "~/utils"
import dayjs from "dayjs"
import { MAIN_ROUTERS, MANAGER_ROUTERS } from "~/routes/routes"
import { getRandomColor } from "~/common/code.helper"
import { CHAIN_CODE } from "~/@config/chain-code"
import { useListCampaign } from "~/views/SocialEngagementView/hooks/useListCampaign"
import { TitleHeader, TitleViewAll } from "~/@ui/common"
import SocialEngagementIconActive from '~/assets/svgs/components/social-engagement-active.svg'


export const SocialEngagementRelateView = (props: {
  campaignId: string,
}) => {

  const { campaignId } = props;
  const { width, height } = useWindowSize();
  const toast = useCustomToast();
  const navigate = useNavigate();
  const { account } = useConnectWallet();

  const {
    isLoading,
    listCampaign,
    loadListCampaign,
  } = useListCampaign();

  useEffect(() => { loadListCampaign(1) }, [loadListCampaign]);

  return (
    <VStack
      w="full"
      pt="30px"
      spacing={4}
    >

      <HStack
        w="full"
        justifyContent={"space-between"}
      >
        <HStack>
          <Image
            w={{ base: "20px", md: "32px" }}
            h={{ base: "20px", md: "32px" }}
            src={SocialEngagementIconActive}
          />
          <TitleHeader>
            NFT Social Engagement
          </TitleHeader>
        </HStack>
        <TitleViewAll
          title=" View All"
          openLink={() => {
            navigate(MANAGER_ROUTERS.SOCIAL_ENGAGEMENT)
          }}
        />
      </HStack>

      <SimpleGrid
        w="full"
        spacing={3}
        columns={{ base: 1, md: 2, lg: 3, xl: 4, '3xl': 5 }}
      >

        {listCampaign
          .filter(c => c.campaignId !== campaignId && (new Date()).getTime() < (new Date(c.endTime)).getTime())
          .map((item, idx) => {

            const isEnd = (new Date()).getTime() > (new Date(item.endTime)).getTime();

            return (
              <Box
                position={"relative"}
                key={idx}
              >

                <Box
                  h="250px"
                  bg="#fff"
                  borderWidth="2px"
                  borderStyle={"solid"}
                  borderColor={getRandomColor()}
                  borderRadius={"8px"}
                  transition="all 500ms ease-in-out"
                />

                <VStack
                  h="250px !important"
                  bottom={0}
                  position={"absolute"}
                  w="full"
                  p={{ base: "15px", }}
                  bg="white"
                  borderRadius="8px"
                  border={{ base: "2px solid #ffd3cb" }}
                  boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
                  cursor={{ lg: "pointer" }}
                  onClick={() => {
                    window.open(`${MAIN_ROUTERS.NFT_COLLECTION}/${item.chainId}/${item.address}`)
                  }}
                  transition="all 500ms ease-in-out"
                  _hover={{
                    transform: "translate(-10px, -10px)"
                  }}
                >

                  <HStack>
                    <Image
                      src={item.collectionLogo}
                      w="45px"
                      h="45px"
                      borderRadius={"24px"}
                    />
                    <Text
                      fontWeight={"600"}
                      fontSize={{ base: "16px" }}
                      color="black.light"
                      letterSpacing="-0.5px"
                      textAlign={"center"}
                      opacity={.8}
                    >
                      {item.collectionName}
                    </Text>
                  </HStack>

                  <Text
                    fontWeight={"600"}
                    fontSize={{ base: "18px" }}
                    color="black.light"
                    letterSpacing="-0.5px"
                    textAlign={"center"}
                  >
                    {item.campaignName}
                  </Text>

                  <Text
                    p="4px 12px"
                    color={"#fff"}
                    border='2px solid #ffd3cb'
                    borderRadius={"8px"}
                    fontWeight={700}
                    fontSize={{ base: "16px", md: "20px" }}
                    lineHeight="21px"
                    letterSpacing="-0.5px"
                    className="textPrimary"
                  >
                    {formatMoney(item?.totalReward?.toString())} {item.chainId === CHAIN_CODE.BASE_MAINNET ? "USDC" : item.currency}
                  </Text>

                  <HStack
                    w="full"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Text
                      p="4px 12px"
                      border='1px solid #dedede'
                      borderRadius={"4px"}
                      fontSize={"14px"}
                      fontWeight={500}
                      className="textPrimary"
                    >
                      {`Participants (${formatMoney(item?.totalUserJoined?.toString()).toString()})`}
                    </Text>
                    <Text
                      p="4px 12px"
                      border='1px solid #dedede'
                      borderRadius={"4px"}
                      fontSize={"12px"}
                      fontWeight={500}
                      color={isEnd ? "#000" : "green"}
                    >
                      {isEnd ? "Ended" : "Live"}
                    </Text>
                  </HStack>

                  <HStack
                    w="full"
                    alignItems={"start"}
                    justifyContent={"center"}
                  >
                    <Text
                      p="4px 12px"
                      border='1px solid #dedede'
                      borderRadius={"4px"}
                      fontSize={"10px"}
                      fontWeight={500}
                      textAlign={"left"}
                    >
                      {`${item?.startTime ? dayjs(new Date(item.startTime).toUTCDate()).format("YYYY/MM/DD HH:mm") : null}
                         -
                        ${item?.endTime ? dayjs(new Date(item.endTime).toUTCDate()).format("YYYY/MM/DD HH:mm") : null}
                        `}
                      (UTC)
                    </Text>
                  </HStack>

                </VStack>

              </Box>
            )
          })
        }

      </SimpleGrid>

    </VStack>
  )
}
