import { useCallback, useEffect, useState } from "react"

import { NftCollection } from "~/dto/nft-project.dto";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import useCustomToast from "~/hooks/@global/useCustomToast";
import nftCollectionService from "~/services/nft-collection.service";


export const useGalxeMission = () => {

  const toast = useCustomToast();
  const { account } = useConnectWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [listGalxe, setListGalxe] = useState<NftCollection[]>([]);

  const loadList = useCallback(async (pageIndex: number,) => {
    setIsLoading(true);
    try {

      const rs = await nftCollectionService.listGalxe({
        pageIndex,
        pageSize: 48,
        environment: true,
        evmAddress: account,
      });

      setListGalxe(rs.data);
      setTotal(rs.total);
      setIsLoading(false);

    } catch (error) {
      console.log(`-------------------`);
      console.log(error);
      console.log(`-------------------`);
      setIsLoading(false);
    }
  }, [account])

  const verifyHolderMint = useCallback(async (
    chainId: number, collectionAddress: string,
  ) => {
    setIsLoading(true);
    try {

      if (account) {
        const rs = await nftCollectionService.verifyHolderMint({
          chainId,
          partner: "GALXE",
          collectionAddress,
          evmAddress: account,
        });
        toast.show({
          type: "success",
          title: "Success!",
          description: `Verify success`,
        })
        await loadList(1);
      }

      setIsLoading(false);

    } catch (error) {
      console.log(`-------------------`);
      console.log(error);
      console.log(`-------------------`);
      setIsLoading(false);
      toast.handleError(error)
    }
  }, [account])

  useEffect(() => {
    loadList(1);
  }, [loadList])

  return {
    isLoading,
    listGalxe,
    total,
    loadList,
    verifyHolderMint,
  }
}
