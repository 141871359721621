import { VStack, HStack, Text, Box } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "~/components/PrimaryButton"
import { useConnectWallet } from "~/hooks/@global/useConnectWallet"
import useCustomToast from "~/hooks/@global/useCustomToast"
import useWindowSize from "~/hooks/useWindowSize"
import { useDaily } from "./hooks/useDaily"
import { GiCheckMark } from "react-icons/gi";

export const DailyMissions = () => {

  const toast = useCustomToast();
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { account, userInfo, loadUserInfo } = useConnectWallet();
  const {
    isLoading,
    checkin,
  } = useDaily();


  return (
    <VStack
      w="full"
      p={{ base: "10px", md: "20px" }}
      bg="#fff"
      borderRadius="20px"
      border={{ base: "1px solid #000" }}
      boxShadow="0px 10px 0px 0px #ffd3cb"
      alignItems={"start"}
    >

      <Text
        color="#000"
        fontWeight={700}
        textAlign="center"
        fontSize="20px"
        lineHeight="24px"
        bg="#ffd3cb9e"
        borderRadius={"8px"}
        px="4px"
      >
        Daily Missions
      </Text>

      <HStack
        w="full"
        justifyContent={"space-between"}
        border={{ base: "1px solid #000" }}
        p="10px"
        borderRadius="20px"
        _hover={{
          boxShadow: "0px 5px 0px 0px #000"
        }}
      >

        <Text
          color="#000"
          fontWeight={500}
          textAlign="center"
          fontSize="16px"
          lineHeight="24px"
        >
          Daily Check-in
        </Text>

        <HStack>

          <Box
            bg="#ffd3cb9e"
            borderRadius={"8px"}
            p="3px 5px"
          >
            <Text
              color="#000"
              fontWeight={600}
              textAlign="center"
              fontSize="14px"
              lineHeight="24px"
            >
              +10 Points
            </Text>
          </Box>

          {!account ?
            null
            :
            <>
              {!userInfo?.allowUserClaimCheckin ?
                <GiCheckMark
                  color="red"
                  fontSize={"24px"}
                />
                :
                <PrimaryButton
                  bg="transparent"
                  color={"#ee3824"}
                  h="30px"
                  borderRadius={"20px"}
                  fontSize={"13px"}
                  w="120px"
                  _hover={{
                    transition: "all 250ms ease-in-out",
                    fontSize: "16px",
                    bg: "transparent"
                  }}
                  onClick={async () => {
                    await checkin();
                  }}
                  isLoading={isLoading}
                  isDisabled={isLoading || !account}
                >
                  Check-in
                </PrimaryButton>
              }
            </>
          }



        </HStack>

      </HStack>

    </VStack>
  )
}
