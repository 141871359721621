/* eslint-disable max-len */
const resources = {
"src/container/ApprovalPurchaseModal/ApprovalPurchaseModal.lang.json": import("~/container/ApprovalPurchaseModal/ApprovalPurchaseModal.lang.json"),
"src/container/ChangePriceNFTModal/ChangePriceNFTModal.lang.json": import("~/container/ChangePriceNFTModal/ChangePriceNFTModal.lang.json"),
"src/container/CollectionDetailItemCard/CollectionDetailItemCard.lang.json": import("~/container/CollectionDetailItemCard/CollectionDetailItemCard.lang.json"),
"src/container/RemoveNFTModal/RemoveNFTModal.lang.json": import("~/container/RemoveNFTModal/RemoveNFTModal.lang.json"),
"src/container/SellNFTModal/SellNFTModal.lang.json": import("~/container/SellNFTModal/SellNFTModal.lang.json"),
"src/container/SendNFTModal/SendNFTModal.lang.json": import("~/container/SendNFTModal/SendNFTModal.lang.json"),
"src/layouts/MasterLayout/Header.lang.json": import("~/layouts/MasterLayout/Header.lang.json"),
"src/layouts/MasterLayout/Sidebar.lang.json": import("~/layouts/MasterLayout/Sidebar.lang.json"),
"src/views/Home/components/Header.lang.json": import("~/views/Home/components/Header.lang.json"),
"src/views/Home/components/Launchpad/LaunchpadCard.lang.json": import("~/views/Home/components/Launchpad/LaunchpadCard.lang.json"),
"src/views/Home/components/Launchpad/LaunchpadCard.mobile.lang.json": import("~/views/Home/components/Launchpad/LaunchpadCard.mobile.lang.json"),
"src/views/Home/components/NewestCollections.lang.json": import("~/views/Home/components/NewestCollections.lang.json"),
"src/views/Home/components/TopSellers.lang.json": import("~/views/Home/components/TopSellers.lang.json"),
"src/views/Home/components/Trending.lang.json": import("~/views/Home/components/Trending.lang.json"),
"src/views/Home/Home.lang.json": import("~/views/Home/Home.lang.json"),
"src/views/Trash/NFTSales/AllProjects.lang.json": import("~/views/Trash/NFTSales/AllProjects.lang.json"),
"src/views/Trash/NFTSales/Claim.lang.json": import("~/views/Trash/NFTSales/Claim.lang.json"),
"src/views/Trash/NFTSales/components/ButtonInfoNFT.lang.json": import("~/views/Trash/NFTSales/components/ButtonInfoNFT.lang.json"),
"src/views/Trash/NFTSales/components/ClaimCard.lang.json": import("~/views/Trash/NFTSales/components/ClaimCard.lang.json"),
"src/views/Trash/NFTSales/components/NFTCard.lang.json": import("~/views/Trash/NFTSales/components/NFTCard.lang.json"),
"src/views/Trash/NFTSales/components/OpeningCard.lang.json": import("~/views/Trash/NFTSales/components/OpeningCard.lang.json"),
"src/views/Trash/NFTSales/components/RefundCard.lang.json": import("~/views/Trash/NFTSales/components/RefundCard.lang.json"),
"src/views/Trash/NFTSales/components/Tag.lang.json": import("~/views/Trash/NFTSales/components/Tag.lang.json"),
"src/views/Trash/NFTSales/components/UpcomingCard.lang.json": import("~/views/Trash/NFTSales/components/UpcomingCard.lang.json"),
"src/views/Trash/NFTSales/DetailNFT.lang.json": import("~/views/Trash/NFTSales/DetailNFT.lang.json"),
"src/views/Trash/NFTSales/Refund.lang.json": import("~/views/Trash/NFTSales/Refund.lang.json"),
"src/views/WalletAccounts/Profile/components/ActivityItem.mobile.lang.json": import("~/views/WalletAccounts/Profile/components/ActivityItem.mobile.lang.json"),
"src/views/WalletAccounts/Profile/components/FavoriteTab.lang.json": import("~/views/WalletAccounts/Profile/components/FavoriteTab.lang.json"),
"src/views/WalletAccounts/Profile/components/MyItemCard.lang.json": import("~/views/WalletAccounts/Profile/components/MyItemCard.lang.json"),
"src/views/WalletAccounts/Profile/components/MyItems.lang.json": import("~/views/WalletAccounts/Profile/components/MyItems.lang.json"),
"src/views/WalletAccounts/Profile/components/OnSales.lang.json": import("~/views/WalletAccounts/Profile/components/OnSales.lang.json"),
"src/views/WalletAccounts/Profile/Profile.lang.json": import("~/views/WalletAccounts/Profile/Profile.lang.json"),
};

export default resources;
