import {
  Modal, ModalOverlay, ModalContent,
  ModalBody, VStack, Spinner, Text, ModalHeader, ModalCloseButton, HStack, Divider, SimpleGrid,
} from "@chakra-ui/react";
import PrimaryButton from "~/components/PrimaryButton";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import { useMilestone } from "../hooks/useMilestone";
import { useEffect } from "react";
import { EClaimStatus } from "~/common/enums/EClaimStatus";
import { GiCheckMark } from "react-icons/gi";

interface IProps {
  isOpen?: boolean
  onClose?: () => void,
}

export const MilestoneCheckinBody = () => {

  const { account } = useConnectWallet();
  const {
    isLoading,
    listMilestone,
    claimMilestoneCheckin,
    listMilestoneCheckin,
  } = useMilestone();

  useEffect(() => {
    listMilestoneCheckin();
  }, [listMilestoneCheckin])

  return (
    <SimpleGrid
      w='full'
      spacing={2}
      columns={{ base: 2, md: 2, lg: 5 }}
    >

      {listMilestone.map((item, idx) => {

        return (
          <VStack
            key={idx}
            w='full'
            borderRadius={"20px"}
            border={{ base: "1px solid #000" }}
            // boxShadow="0px 10px 0px 0px #ffd3cb"
            h="200px"
            justifyContent={"center"}

            _hover={{
              boxShadow: "0px 5px 0px 0px #000"
            }}
          >

            <Text
              fontWeight={700}
              fontSize={{ base: "18px" }}
              lineHeight={{ base: "26px" }}
              textAlign={"left"}
            >
              Day {item?.valueCheck || ""}
            </Text>

            <PrimaryButton
              h="30px"
              w="fit-content"
              bg="transparent"
              color={"#ee3824"}
              borderRadius={"20px"}
              fontSize={"13px"}
              fontWeight={600}
              _hover={{
                transition: "all 250ms ease-in-out",
                fontSize: "16px",
                bg: "transparent"
              }}
              _disabled={{
                opacity: .7,
                borderColor: '#E8E8E8 !important',
                backgroundColor: '#E8E8E8 !important',
                color: '#ee3824 !important',
                cursor: "not-allowed"
              }}
              onClick={() => {
                claimMilestoneCheckin(item.id)
              }}
              isLoading={isLoading}
              isDisabled={isLoading || !account || item?.status !== EClaimStatus.NOT_CLAIMED}
              leftIcon={item?.status ? (item.status === EClaimStatus.CLAIMED ? <GiCheckMark color="#ee3824" /> : null) : null}
            >
              {item?.bonusPoint ? `${item.bonusPoint} Point` : ""}
            </PrimaryButton>

          </VStack>
        )
      })
      }

    </SimpleGrid >
  )
}

export const MilestoneCheckinModal = (props: IProps) => {

  const {
    isOpen,
    onClose,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >

      <ModalOverlay
        backdropFilter='blur(3px)'
        backgroundColor="rgba(0, 0, 0, 0.5)"
      />

      <ModalContent
        bg="#fff"
        maxWidth="1100px"
        borderRadius="20px"
        border={{ base: "1px solid #000" }}
        boxShadow="0px 10px 0px 0px #ffd3cb"
      >

        <HStack
          borderTopRadius="20px"
          bg="#ffd3cb9e"
          p="25px"
          borderBottom={{ base: "1px solid #000" }}
        >

          <Text
            fontWeight={700}
            fontSize={{ base: "20px" }}
            lineHeight={{ base: "29px" }}
            textAlign={"left"}
          >
            Milestone of Check-in
          </Text>

          <ModalCloseButton bg="#FFF" mt="15px" />
        </HStack>


        <ModalBody
          w="full"
          bg="transparent"
        >

          <VStack
            w="full"
            spacing={4}
            bg="transparent"
            p="20px 10px"
          >

            <Text
              fontWeight={400}
              fontSize={{ base: "13px" }}
              lineHeight={{ base: "18px" }}
              textAlign={"left"}
            >
              Uncover the hierarchy of user ranks through a collection of badges that signify different tiers within our community. These badges succinctly outline the various stages of progression.
            </Text>

            <Divider variant="dashed" borderBottomColor={"#000"} />

            <MilestoneCheckinBody />

          </VStack>

        </ModalBody>

      </ModalContent>

    </Modal >
  )
}
