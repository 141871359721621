import { useCallback, useEffect, useState } from "react"
import { PageResponse } from "~/dto/@common"
import { IHistoryMissions } from "~/dto/IHistoryMissions";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import useCustomToast from "~/hooks/@global/useCustomToast";
import missionsService from "~/services/missions.service";


export const useHistoryMission = () => {

  const toast = useCustomToast();
  const { account } = useConnectWallet();
  const [isLoading, setIsLoading] = useState(false);

  const [response, setResponse] = useState<PageResponse<IHistoryMissions>>({
    data: [],
    total: 0
  });


  const loadData = useCallback(async (pageIndex) => {
    setIsLoading(true);
    try {
      if (account) {
        const { data, total } = await missionsService.historyList({
          pageIndex,
          pageSize: 25,
          walletAddress: account
        });

        setResponse({
          data,
          total
        })
      }

    } catch (error) {
      console.log({ error });

    }
    setIsLoading(false);

  }, [account]);


  return {
    isLoading,
    ...response,
    loadData,
  }
}
