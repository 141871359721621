import {
  Modal, ModalOverlay, ModalContent,
  ModalBody, VStack, Spinner, Text, ModalHeader, ModalCloseButton, HStack, Divider, SimpleGrid,
} from "@chakra-ui/react";
import PrimaryButton from "~/components/PrimaryButton";



interface IProps {
  isOpen?: boolean
  onClose?: () => void,
}

export const MilestoneQuestModal = (props: IProps) => {

  const {
    isOpen,
    onClose,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >

      <ModalOverlay
        backdropFilter='blur(3px)'
        backgroundColor="rgba(0, 0, 0, 0.5)"
      />

      <ModalContent
        bg="#fff"
        maxWidth="1100px"
        borderRadius="20px"
        border={{ base: "1px solid #000" }}
        boxShadow="0px 10px 0px 0px #ffd3cb"
      >

        <HStack
          borderTopRadius="20px"
          bg="#ffd3cb9e"
          p="25px"
          borderBottom={{ base: "1px solid #000" }}
        >

          <Text
            fontWeight={700}
            fontSize={{ base: "20px" }}
            lineHeight={{ base: "29px" }}
            textAlign={"left"}
          >
            Milestone of Quests
          </Text>

          <ModalCloseButton bg="#FFF" mt="15px" />
        </HStack>


        <ModalBody
          w="full"
          bg="transparent"
        >

          <VStack
            w="full"
            spacing={4}
            bg="transparent"
            p="20px 10px"
          >

            <Text
              fontWeight={400}
              fontSize={{ base: "13px" }}
              lineHeight={{ base: "18px" }}
              textAlign={"left"}
            >
              Uncover the hierarchy of user ranks through a collection of badges that signify different tiers within our community. These badges succinctly outline the various stages of progression.
            </Text>

            <Divider variant="dashed" borderBottomColor={"#000"} />

            <SimpleGrid
              w='full'
              spacing={6}
              columns={{ base: 1, md: 2, lg: 4 }}
            >

              {[1, 2, 3, 4, 5, 6, 7, 8].map((item, idx) => {

                return (
                  <VStack
                    w='full'
                    borderRadius={"20px"}
                    border={{ base: "1px solid #000" }}
                    boxShadow="0px 10px 0px 0px #ffd3cb"
                    h="200px"
                    justifyContent={"center"}
                  >

                    <Text
                      fontWeight={700}
                      fontSize={{ base: "18px" }}
                      lineHeight={{ base: "26px" }}
                      textAlign={"left"}
                    >
                      Milestone {idx + 1}
                    </Text>

                    <Text
                      fontWeight={400}
                      fontSize={{ base: "18px" }}
                      lineHeight={{ base: "26px" }}
                      textAlign={"left"}
                    >
                      Completed {5 * (idx + 1)} Quest
                    </Text>

                    <PrimaryButton
                      w="fit-content"
                      bg="transparent"
                      color={"#ee3824"}
                      h="30px"
                      borderRadius={"20px"}
                      isDisabled={idx ? true : false}
                    >
                      Claim
                    </PrimaryButton>

                  </VStack>
                )
              })

              }

            </SimpleGrid>

          </VStack>

        </ModalBody>

      </ModalContent>

    </Modal >
  )
}
