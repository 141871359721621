import { VStack, HStack, Text, Grid, GridItem } from "@chakra-ui/react"
import { Fragment } from "react"
import { TitleCustom } from "../LeaderboardView"
import { ValueGrid } from "../LeaderboardView/ValueGrid"
import { Leaderboard } from "~/dto/point.dto";
import { useLeaderboardMission } from "./hooks/useLeaderboardMission"
import { pipeLongTextUi } from "~/common/utils/common.utils";


export const LeaderboardMissions = () => {

  const { data, total, isLoading, } = useLeaderboardMission();


  const renderContent = (idx: number, item: Leaderboard) => {

    return (
      <Grid
        key={idx}
        w="full"
        mt={{ md: "10px" }}
        padding="1px 15px"
        templateColumns='repeat(13, 1fr)'
        gap={{ base: 0, lg: 2 }}
        bg="white"
        borderRadius="8px"
        border={{ base: "1px solid #ffd3cb", md: "none" }}
        boxShadow={{ base: "0px 0px 14px rgba(186, 186, 186, 0.25)", md: "none" }}
        _hover={{
          boxShadow: "0px 5px 0px 0px #000"
        }}
      >

        <GridItem colSpan={{ base: 13, md: 4 }}  >
          <ValueGrid
            t="Rank:"
            value={item.pointRank}
          />
        </GridItem>

        <GridItem colSpan={{ base: 13, md: 6 }}  >
          <ValueGrid
            t="Address:"
            value={pipeLongTextUi(item?.evmAddress, 10, 0)}
            isShortText={true}
          />
        </GridItem>

        <GridItem colSpan={{ base: 13, md: 3 }}  >
          <ValueGrid
            t="Points:"
            value={item?.point}
          />
        </GridItem>

      </Grid>
    )
  }

  return (
    <VStack
      w="full"
      p={{ base: "10px", md: "20px" }}
      bg="#fff"
      borderRadius="20px"
      border={{ base: "1px solid #000" }}
      boxShadow="0px 10px 0px 0px #ffd3cb"
      alignItems={"start"}
    >

      <Text
        color="#000"
        fontWeight={700}
        textAlign="center"
        fontSize="20px"
        lineHeight="24px"
        bg="#ffd3cb9e"
        borderRadius={"8px"}
        px="4px"
        mb="10px"
      >
        Leaderboard
      </Text>


      <Grid
        w="full"
        templateColumns='repeat(13, 1fr)'
        gap={6}
        pt={{ md: "12px" }}
        pb={{ md: "20px" }}
        display={{ base: "none", md: "grid" }}
        borderBottom="1px solid #ffd3cb"
      >

        <GridItem colSpan={{ base: 12, md: 4 }}  >
          <TitleCustom t="Rank" />
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 5 }}  >
          <TitleCustom t="Address" />
        </GridItem>

        <GridItem colSpan={{ base: 12, md: 4 }}  >
          <TitleCustom t="Points" />
        </GridItem>

      </Grid>

      <VStack
        spacing={{ base: 2, lg: 3 }}
        w="full"
      >

        {data.map((item, idx) => {
          return (
            <Fragment key={idx}>
              {renderContent(idx, item)}
            </Fragment>
          )
        })
        }

      </VStack>

    </VStack>
  )
}
