import { useCallback, useEffect, useState } from "react"
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import useCustomToast from "~/hooks/@global/useCustomToast";
import missionsService from "~/services/missions.service";


export const useDaily = () => {

  const toast = useCustomToast();
  const { account, loadUserInfo } = useConnectWallet();
  const [isLoading, setIsLoading] = useState(false);

  const checkin = useCallback(async () => {
    setIsLoading(true);
    try {
      if (account) {
        await missionsService.checkin({
          evmAddress: account
        });
        loadUserInfo();
        toast.show({
          type: "success",
          title: "Success!",
          description: `Check-in success`,
        })
      }

    } catch (error) {
      console.log({ error });

      toast.handleError(error);
    }
    setIsLoading(false);
  }, [account,])


  return {
    isLoading,
    checkin,
  }
}
