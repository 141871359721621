import { useCallback, useEffect, useState } from "react"

import { NftCollection } from "~/dto/nft-project.dto";
import useCustomToast from "~/hooks/@global/useCustomToast";
import nftCollectionService from "~/services/nft-collection.service";


export const useSpecialMission = () => {

  const toast = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [listSpecialMission, setListSpecialMission] = useState<NftCollection[]>([]);

  const loadList = useCallback(async (pageIndex: number,) => {
    setIsLoading(true);
    try {

      const rs = await nftCollectionService.listSpecialMission({
        pageIndex,
        pageSize: 48,
        environment: true
      });

      setListSpecialMission(rs.data);
      setTotal(rs.total);
      setIsLoading(false);

    } catch (error) {
      console.log(`-------------------`);
      console.log(error);
      console.log(`-------------------`);
      setIsLoading(false);
    }
  }, [])

  useEffect(() => {
    loadList(1);
  }, [loadList])

  return {
    isLoading,
    listSpecialMission,
    total,
    loadList,
  }
}
