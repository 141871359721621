import { VStack, HStack, Text, SimpleGrid, Box, Image, Grid, GridItem, Wrap, WrapItem, } from "@chakra-ui/react"
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { MAIN_ROUTERS } from "~/routes/routes";
import NoImg from "~/assets/images/no_img.png"
import { IoPlayCircleOutline } from 'react-icons/io5';
import { useGalxeMission } from "./hooks/useGalxeMission";
import { GiCheckMark } from "react-icons/gi";
import PrimaryButton from "~/components/PrimaryButton";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";


export const GalxeMission = () => {

  const navigate = useNavigate();
  const { account } = useConnectWallet();
  const videoRef = useRef(null);

  const {
    isLoading,
    listGalxe,
    total,
    verifyHolderMint,
  } = useGalxeMission();

  useEffect(() => {
    videoRef?.current?.load()
  }, [])

  return (
    <VStack
      w="full"
      p={{ base: "10px", md: "20px" }}
      bg="#fff"
      borderRadius="20px"
      border={{ base: "1px solid #000" }}
      boxShadow="0px 10px 0px 0px #ffd3cb"
      alignItems={"start"}
    >

      <Text
        color="#000"
        fontWeight={700}
        textAlign="center"
        fontSize="20px"
        lineHeight="24px"
        bg="#ffd3cb9e"
        borderRadius={"8px"}
        px="4px"
        mb="10px"
      >
        Platform Missions
      </Text>

      <VStack
        mt={{ base: "10px", md: "20px" }}
        w='full'
        spacing={4}
        h="300px"
        overflowY={"auto"}
        p="10px"
      >

        {listGalxe.map((item, idx) => {

          const { image } = item;

          const renderImage = () => {
            return (
              <VStack
              >

                {
                  image.includes(".mp4")
                    ?
                    <Box
                      height="inherit"
                      position={"relative"}
                      h={{ base: "40px", }}
                      w={{ base: "40px", }}
                      p={{ base: "3px", md: "0px" }}
                      borderRadius={{ base: "8px", md: "24px" }}
                    >

                      <Box
                        w="100%"
                        height="inherit"
                      >
                        <video
                          ref={videoRef}
                          //@ts-ignore
                          playsInline="playsInline"
                          //@ts-ignore
                          muted="muted"
                          style={{
                            borderRadius: "24px",
                            width: "100%",
                            height: "inherit",
                            objectFit: "cover"
                          }}
                          controls={false}
                        >
                          <source src={image} type="video/mp4" />
                        </video>
                      </Box>

                      <Box
                        bg="#fff"
                        borderRadius={"8px"}
                        bottom={1}
                        left={2}
                        position={"absolute"}
                        cursor={{ lg: 'pointer' }}
                        onClick={(e) => {
                          e.stopPropagation();
                          videoRef.current.play();
                        }}
                      >
                        <IoPlayCircleOutline size={"10px"} />
                      </Box>
                    </Box>
                    :
                    <Image
                      background="rgba(236, 236, 236)"
                      borderRadius={"24px"}
                      src={image}
                      h={{ base: "30px", md: "40px" }}
                      w={{ base: "30px", md: "40px" }}
                      p={{ base: "3px", md: "0px" }}
                      objectFit={"contain"}
                      fallbackSrc={NoImg}
                      loading="lazy"
                    />
                }

              </VStack>
            )
          }

          return (
            <Grid
              key={idx}
              gap={2}
              templateColumns="repeat(7, 1fr)"
              w="full"
              justifyContent={"space-between"}
              border={{ base: "1px solid #000" }}
              p="5px 10px"
              borderRadius="20px"
              cursor={{ lg: "pointer" }}
              onClick={() => {
                if (item.galxeHref) {
                  window.open(item.galxeHref)
                } else {
                  window.open(`${MAIN_ROUTERS.NFT_COLLECTION}/${item.chainId}/${item.address}`)
                }
              }}
              _hover={{
                boxShadow: "0px 5px 0px 0px #000"
              }}
            >

              <GridItem
                w='full'
                colSpan={{ base: 7, md: 5 }}
              >

                <HStack
                  w="full"
                  alignItems={'center'}
                >

                  {renderImage()}

                  <Text
                    color="#000"
                    fontWeight={500}
                    textAlign="left"
                    fontSize="16px"
                    lineHeight="24px"
                    noOfLines={1}
                    maxW={{ base: "80%", md: "100%" }}
                  >
                    {item.name}
                  </Text>

                </HStack>

              </GridItem>

              <GridItem
                w='full'
                colSpan={{ base: 7, md: 2 }}
                alignSelf={'center'}
              >

                <VStack
                  w='full'
                  alignItems={{ base: "center", md: 'end' }}
                >

                  <HStack>
                    <Box
                      bg="#ffd3cb9e"
                      borderRadius={"8px"}
                      p="3px 5px"
                    >
                      <Text
                        color="#000"
                        fontWeight={600}
                        textAlign="center"
                        fontSize="14px"
                        lineHeight="24px"
                      >
                        +{item.mintPoint} Points
                      </Text>
                    </Box>

                    {item.isQuestFinish > 0 ?
                      <GiCheckMark
                        color="red"
                        fontSize={"24px"}
                      />
                      :
                      <PrimaryButton
                        w="fit-content"
                        bg="transparent"
                        color={"#ee3824"}
                        h="30px"
                        borderRadius={"20px"}
                        fontSize={"14px"}
                        _hover={{
                          transition: "all 250ms ease-in-out",
                          fontSize: "13px",
                          bg: "transparent"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          verifyHolderMint(item.chainId, item.address)
                        }}
                        isLoading={isLoading}
                        isDisabled={isLoading || !account}
                      >
                        Verify
                      </PrimaryButton>
                    }
                  </HStack>

                </VStack>

              </GridItem>

            </Grid>
          )
        })
        }

      </VStack>


    </VStack>
  )
}
