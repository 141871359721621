import { CHAIN_CODE } from "../chain-code";
import { IEnvConfig } from "../dto";

const config: IEnvConfig = {
  name: "DEVELOPMENT",
  CONNECTORS: {
    ROOT: {
      //baseUrl: "https://i198e9817g.execute-api.ap-southeast-1.amazonaws.com/prod/mvs-dapp"
      baseUrl: "https://api.nftfeed.guru",
    },
  },
  SSO: {
    loginUrl: "https://sso.rex-labs.com/login".trim(),
    clientId: "nft_feed".trim(),
  },
  applyChainIds: [
    //   CHAIN_CODE.ARBITRUM_GOERLI_TESTNET,
    //   CHAIN_CODE.OP_GOERLI_TESTNET,
    // CHAIN_CODE.BASE_GOERLI_TESTNET,
    // CHAIN_CODE.POLYGON_TESTNET,
    // CHAIN_CODE.OP_MAINNET, CHAIN_CODE.SCROLL_MAINNET,
    // CHAIN_CODE.BASE_MAINNET, CHAIN_CODE.ARBITRUM_MAINNET,
    // CHAIN_CODE.ZETACHAIN_TESTNET, CHAIN_CODE.POLYGON_MAINNET,
    // CHAIN_CODE.AVAX, CHAIN_CODE.ZORA, CHAIN_CODE.OPBNB, CHAIN_CODE.BSC_MAINNET,
    // CHAIN_CODE.LINEA,
    CHAIN_CODE.BSC_TESTNET,
    CHAIN_CODE.ZETACHAIN_TESTNET,
    CHAIN_CODE.BLAST_TESTNET,
  ],
  customSMC: {
    // OP
    [CHAIN_CODE.OP_GOERLI_TESTNET]: {
      MINT_NFT_FACTORY: "0x2c9FF4b226B36D1e180E728fb342D74f82D32b4E",
      MINT_NFT_FACTORY_V2: "".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    // BASE
    [CHAIN_CODE.BASE_GOERLI_TESTNET]: {
      MINT_NFT_FACTORY: "0x21E5FD0274e255a5F0D1ef544822aE2bcb71773a",
      MINT_NFT_FACTORY_V2: "0x21587995689c10DC375C87d44e3e8b2220eC31b8".trim(),
      STAKE_NFT_AUTO_APY: "0x5C4401307264c16BbDbe64aB09b21A9F6DD17d87".trim(),
      WSHARKIE: "0x295B04B08a425c882C34a4ce0a85255cD22281fc".trim(),
      SHARKIE_NFT: "0xD858f831511f9e58c5146f4ed643c8bf55ACCfb4".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    // Zeta
    [CHAIN_CODE.ZETACHAIN_TESTNET]: {
      MINT_NFT_FACTORY: "",
      MINT_NFT_FACTORY_V2: "0xbcc2e7dde130ef6cb20ad4daa360cfa4e6e2b9ac".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.ARBITRUM_GOERLI_TESTNET]: {
      MINT_NFT_FACTORY: "",
      MINT_NFT_FACTORY_V2: "0x15ca7e1203F25e4a56D86faCB6c4715bbA52e795".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },

    // BASE
    [CHAIN_CODE.BASE_MAINNET]: {
      MINT_NFT_FACTORY: "0xA08b02513d9849E64b14640E32e2f166422e2583",
      MINT_NFT_FACTORY_V2: "0x2C6B315391E753Ecb2c1DdCA480D11f19E345862".trim(),
      STAKE_NFT_AUTO_APY: "0xfc161FFb28599a0d07941c265F4FcFeF60f3034f".trim(),
      WSHARKIE: "0x03D8A7ad755E5645145D0a7c3CFD0DCF5F52DF8C".trim(),
      SHARKIE_NFT: "0x268174ff68633901a2ec6511b33c590aac4fe263".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "0x27dac2f25edb24c75cd1781e68b0358c91a9765a".trim(),
      BPUNK_STAKE_NFT_AUTO_APY:
        "0xAcA5813d51D721B28CB8aC9469818B7462591506".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },

    // OP
    [CHAIN_CODE.OP_MAINNET]: {
      MINT_NFT_FACTORY: "0x8D062f89e71FF11C8b8914558995DD4e9862D981",
      MINT_NFT_FACTORY_V2: "0xAf1FF8e04Aa97d2C155fBa9829CB152169bfD8fD".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721:
        "0x12CcE7bcA11364Da0D120Fb82B4042a46a8Cf586".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.ZKSYNC_ERA_MAINNET]: {
      MINT_NFT_FACTORY: "",
      MINT_NFT_FACTORY_V2: "".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.ARBITRUM_MAINNET]: {
      MINT_NFT_FACTORY: "",
      MINT_NFT_FACTORY_V2: "0xAf1FF8e04Aa97d2C155fBa9829CB152169bfD8fD".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.SCROLL_MAINNET]: {
      MINT_NFT_FACTORY: "",
      MINT_NFT_FACTORY_V2: "0xAf1FF8e04Aa97d2C155fBa9829CB152169bfD8fD".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.POLYGON_MAINNET]: {
      MINT_NFT_FACTORY: "".trim(),
      MINT_NFT_FACTORY_V2: "0xC5D9f31180579284eeB5f891dDc7b1c83eFfD63d".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.AVAX]: {
      MINT_NFT_FACTORY: "".trim(),
      MINT_NFT_FACTORY_V2: "0xe24165ba2bE15a27f5569d179C1bB07369Bc903a".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.ZORA]: {
      MINT_NFT_FACTORY: "".trim(),
      MINT_NFT_FACTORY_V2: "0x19fb5a5ee3d93E8a5BdC330AC8D04B496C91792e".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.OPBNB]: {
      MINT_NFT_FACTORY: "".trim(),
      MINT_NFT_FACTORY_V2: "0xe24165ba2bE15a27f5569d179C1bB07369Bc903a".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.BSC_MAINNET]: {
      MINT_NFT_FACTORY: "".trim(),
      MINT_NFT_FACTORY_V2: "0xe24165ba2bE15a27f5569d179C1bB07369Bc903a".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.LINEA]: {
      MINT_NFT_FACTORY: "".trim(),
      MINT_NFT_FACTORY_V2: "0xe24165ba2bE15a27f5569d179C1bB07369Bc903a".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.BSC_TESTNET]: {
      MINT_NFT_FACTORY: "",
      MINT_NFT_FACTORY_V2: "0xA0AB7FA5415bec56D6fB7698b4C53cbe3d55598e".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "0x59d69d3be557f6d50cC5E34FA894377Fd5548C15".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1:
        "0xa8d4c599a2d04FB72141541C56fB8403077E8577".trim(),
      USDT: "0xb14E187575b71999a5D39f68944c00B48Dd17Cc0".trim(),
      SOCIAL_VAULT: "0x3e0003917E78a924F46356eF3036304ca2aF350B".trim(),
    },
    [CHAIN_CODE.ZETACHAIN_TESTNET]: {
      MINT_NFT_FACTORY: "",
      MINT_NFT_FACTORY_V2: "".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "0xF13CD5B5178FE50480c1087Dd61b34E08362Da11".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1:
        "0x52d4787c42745F6053F3646074a7B616856DC5c1".trim(),
      USDT: "".trim(),
    },
    [CHAIN_CODE.BLAST_TESTNET]: {
      MINT_NFT_FACTORY: "",
      MINT_NFT_FACTORY_V2: "".trim(),
      STAKE_NFT_AUTO_APY: "".trim(),
      WSHARKIE: "".trim(),
      SHARKIE_NFT: "".trim(),
      STAKE_MULTIPLE_ERC721: "".trim(),

      BPUNK_NFT: "".trim(),
      BPUNK_STAKE_NFT_AUTO_APY: "".trim(),
      TOKEN_FACTORY_V1: "".trim(),
      PRESALE_FAIR_LAUNCH_FACTORY_V1: "".trim(),
      USDT: "".trim(),
    },
  },
};

export default config;
