import { useCallback, useEffect, useState } from "react"
import { EMilestoneType } from "~/common/enums/EMilestoneType";
import { IMilestone } from "~/dto/IMilestone";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import useCustomToast from "~/hooks/@global/useCustomToast";
import missionsService from "~/services/missions.service";


export const useMilestone = () => {

  const toast = useCustomToast();
  const { account, loadUserInfo } = useConnectWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [listMilestone, setListListMilestone] = useState<IMilestone[]>([]);

  const listMilestoneCheckin = useCallback(async () => {
    setIsLoading(true);
    try {
      if (account) {
        const rs = await missionsService.listMilestoneConfig({
          evmAddress: account,
          type: EMilestoneType.CHECK_IN
        });
        setListListMilestone(rs);
      }

    } catch (error) {
      console.log({ error });
      setListListMilestone([]);
    }
    setIsLoading(false);
  }, [account,])

  const claimMilestoneCheckin = useCallback(async (id: string) => {
    setIsLoading(true);
    try {
      if (account && id) {
        await missionsService.claimMilestoneConfig({
          evmAddress: account,
          type: EMilestoneType.CHECK_IN,
          milestoneConfigId: id,
        });
        await listMilestoneCheckin();
        loadUserInfo();
        toast.show({
          type: "success",
          title: "Success!",
          description: `Claim Milestone success`,
        })
      }

    } catch (error) {
      console.log({ error });
      toast.handleError(error)
    }
    setIsLoading(false);
  }, [account,])

  return {
    isLoading,
    listMilestone,
    listMilestoneCheckin,
    claimMilestoneCheckin,
  }
}
