import { VStack, HStack, Text, Box, Divider } from "@chakra-ui/react"
import { GiCheckMark } from "react-icons/gi";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import { MAIN_MENUS, MANAGER_ROUTERS } from "~/routes/routes";

export const XMissions = () => {

  const { userInfo, account } = useConnectWallet();

  return (
    <VStack
      w="full"
      p={{ base: "10px", md: "20px" }}
      bg="#fff"
      borderRadius="20px"
      border={{ base: "1px solid #000" }}
      boxShadow="0px 10px 0px 0px #ffd3cb"
      alignItems={"start"}
    >

      <Text
        color="#000"
        fontWeight={700}
        textAlign="center"
        fontSize="20px"
        lineHeight="24px"
        bg="#ffd3cb9e"
        borderRadius={"8px"}
        px="4px"
        mb="10px"
      >
        X Missions
      </Text>


      <HStack
        w="full"
        justifyContent={"space-between"}
        border={{ base: "1px solid #000" }}
        p="10px"
        borderRadius="20px"
        _hover={{
          boxShadow: "0px 5px 0px 0px #000"
        }}
      >

        <Text
          color="#000"
          fontWeight={500}
          textAlign="center"
          fontSize="16px"
          lineHeight="24px"
        >
          Connect Wallet
        </Text>

        <HStack>
          <Box
            bg="#ffd3cb9e"
            borderRadius={"8px"}
            p="3px 5px"
          >
            <Text
              color="#000"
              fontWeight={600}
              textAlign="center"
              fontSize="14px"
              lineHeight="24px"
            >
              +20 Points
            </Text>
          </Box>
          {account &&
            <GiCheckMark
              color="red"
              fontSize={"24px"}
            />
          }
        </HStack>


      </HStack>

      <HStack
        w="full"
        justifyContent={"space-between"}
        border={{ base: "1px solid #000" }}
        p="10px"
        borderRadius="20px"
        _hover={{
          boxShadow: "0px 5px 0px 0px #000"
        }}
        cursor={{ lg: "pointer" }}
        onClick={() => {
          window.open(`${MANAGER_ROUTERS.SOCIAL_ACCOUNT}`)
        }}
      >

        <Text
          color="#000"
          fontWeight={500}
          textAlign="center"
          fontSize="16px"
          lineHeight="24px"
        >
          Connect X
        </Text>


        <HStack>
          <Box
            bg="#ffd3cb9e"
            borderRadius={"8px"}
            p="3px 5px"
          >
            <Text
              color="#000"
              fontWeight={600}
              textAlign="center"
              fontSize="14px"
              lineHeight="24px"
            >
              +20 Points
            </Text>
          </Box>
          {userInfo?.status >= 1 &&
            <GiCheckMark
              color="red"
              fontSize={"24px"}
            />
          }
        </HStack>

      </HStack>

      <HStack
        w="full"
        justifyContent={"space-between"}
        border={{ base: "1px solid #000" }}
        p="10px"
        borderRadius="20px"
        _hover={{
          boxShadow: "0px 5px 0px 0px #000"
        }}
        cursor={{ lg: "pointer" }}
        onClick={() => {
          window.open(`${MANAGER_ROUTERS.SOCIAL_ACCOUNT}`)
        }}
      >

        <Text
          color="#000"
          fontWeight={500}
          textAlign="center"
          fontSize="16px"
          lineHeight="24px"
        >
          Follow NFTFeed on X
        </Text>


        <HStack>
          <Box
            bg="#ffd3cb9e"
            borderRadius={"8px"}
            p="3px 5px"
          >
            <Text
              color="#000"
              fontWeight={600}
              textAlign="center"
              fontSize="14px"
              lineHeight="24px"
            >
              +20 Points
            </Text>
          </Box>
          {userInfo?.status >= 2 &&
            <GiCheckMark
              color="red"
              fontSize={"24px"}
            />
          }
        </HStack>

      </HStack>

      <Divider variant="dashed" borderBottomColor={"#000"} mt="15px" />

      <Text
        fontWeight={400}
        fontSize={{ base: "13px" }}
        lineHeight={{ base: "18px" }}
        textAlign={"center"}
        alignSelf={"center"}
      >
        Users must complete all 3 tasks to earn 60 points
      </Text>
      <Text
        fontWeight={400}
        fontSize={{ base: "13px" }}
        lineHeight={{ base: "18px" }}
        textAlign={"center"}
        alignSelf={"center"}
      >
        Users who previously verified their X account before the Point Hunter event will have their snapshot taken and additional points added later.
      </Text>

    </VStack>
  )
}
