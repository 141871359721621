import {
  Modal, ModalOverlay, ModalContent,
  ModalBody, VStack, Spinner, Text, ModalHeader, ModalCloseButton, HStack, Divider, SimpleGrid, Grid, GridItem,
} from "@chakra-ui/react";
import { Fragment, useCallback, useEffect } from "react";
import { TitleCustom } from "../../LeaderboardView";
import { ValueGrid } from "../../LeaderboardView/ValueGrid";
import dayjs from "dayjs";
import { useHistoryMission } from "../hooks/useHistoryMission";
import { IHistoryMissions } from "~/dto/IHistoryMissions";
import { ETextPointHistoryType } from "~/common/enums/EPointHistoryType";
import PaginationComponent from "~/components/Pagination"
import { usePagination } from "@ajna/pagination"

interface IProps {
  isOpen?: boolean
  onClose?: () => void,
}

export const HistoryMissionsModal = (props: IProps) => {

  const {
    isOpen,
    onClose,
  } = props;


  const { data, total, isLoading, loadData } = useHistoryMission();

  useEffect(() => {
    if (isOpen) {
      loadData(1)
    }
  }, [loadData, isOpen])

  const { pages, pagesCount, currentPage, setCurrentPage } = usePagination({
    total,
    limits: {
      outer: 2,
      inner: 2,
    },
    initialState: {
      pageSize: 25,
      isDisabled: false,
      currentPage: 1,
    },
  })

  const handlePageChange = useCallback(
    (nextPage: number): void => {
      // -> request new data using the page number
      setCurrentPage(nextPage)
      console.log('request new data with ->', nextPage)
      loadData(nextPage);
    },
    [loadData, setCurrentPage],
  )

  const renderContent = (idx: number, item: IHistoryMissions) => {

    return (
      <Grid
        key={idx}
        w="full"
        mt={{ md: "10px" }}
        padding="1px 15px"
        templateColumns='repeat(13, 1fr)'
        gap={{ base: 0, lg: 2 }}
        bg="white"
        borderRadius="8px"
        border={{ base: "1px solid #ffd3cb", md: "none" }}
        boxShadow={{ base: "0px 0px 14px rgba(186, 186, 186, 0.25)", md: "none" }}
        _hover={{
          boxShadow: "0px 5px 0px 0px #000"
        }}
      >

        <GridItem colSpan={{ base: 13, md: 3 }}  >
          <ValueGrid
            t="No:"
            value={idx + 1}
          />
        </GridItem>

        <GridItem colSpan={{ base: 13, md: 3 }}  >
          <ValueGrid
            t="Time:"
            value={dayjs(new Date(item.createdDate).toUTCDate()).format("HH:mm YYYY/MM/DD")}
            isShortText={true}
          />
        </GridItem>

        <GridItem colSpan={{ base: 13, md: 3 }}  >
          <ValueGrid
            t="Points:"
            value={item?.point}
          />
        </GridItem>

        <GridItem colSpan={{ base: 13, md: 3 }}  >
          <ValueGrid
            t="Action:"
            value={ETextPointHistoryType[item?.type].text}
          />
        </GridItem>

      </Grid>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
    >

      <ModalOverlay
        backdropFilter='blur(3px)'
        backgroundColor="rgba(0, 0, 0, 0.5)"
      />

      <ModalContent
        bg="#fff"
        maxWidth="1100px"
        borderRadius="20px"
        border={{ base: "1px solid #000" }}
        boxShadow="0px 10px 0px 0px #ffd3cb"
        m="10px"
      >

        <HStack
          borderTopRadius="20px"
          bg="#ffd3cb9e"
          p="25px"
          borderBottom={{ base: "1px solid #000" }}
        >

          <Text
            fontWeight={700}
            fontSize={{ base: "20px" }}
            lineHeight={{ base: "29px" }}
            textAlign={"left"}
          >
            History
          </Text>

          <ModalCloseButton bg="#FFF" mt="15px" />
        </HStack>


        <ModalBody
          w="full"
          bg="transparent"
          p="0px"
        >

          <VStack
            w="full"
            spacing={4}
            bg="transparent"
            p={{ base: "10px", md: "20px 10px" }}
          >

            <Text
              fontWeight={400}
              fontSize={{ base: "13px" }}
              lineHeight={{ base: "18px" }}
              textAlign={"left"}
            >
              Check your points during the event: Snapshot Time - Points Earned - Completed Tasks.
            </Text>

            <Divider variant="dashed" borderBottomColor={"#000"} />

            <Grid
              w="full"
              templateColumns='repeat(13, 1fr)'
              gap={6}
              pt={{ md: "12px" }}
              pb={{ md: "20px" }}
              display={{ base: "none", md: "grid" }}
              borderBottom="1px solid #ffd3cb"
            >

              <GridItem colSpan={{ base: 12, md: 3 }}  >
                <TitleCustom t="No." />
              </GridItem>

              <GridItem colSpan={{ base: 12, md: 3 }}  >
                <TitleCustom t="Time" />
              </GridItem>

              <GridItem colSpan={{ base: 12, md: 3 }}  >
                <TitleCustom t="Points" />
              </GridItem>

              <GridItem colSpan={{ base: 12, md: 3 }}  >
                <TitleCustom t="Action" />
              </GridItem>

            </Grid>

            <VStack
              spacing={{ base: 2, lg: 3 }}
              w="full"
            >

              {data.map((item, idx) => {
                return (
                  <Fragment key={idx}>
                    {renderContent(idx, item)}
                  </Fragment>
                )
              })
              }

            </VStack>

          </VStack>

          {!isLoading &&
            <PaginationComponent
              pagesCount={pagesCount}
              currentPage={currentPage}
              isDisabled={isLoading}
              onPageChange={handlePageChange}
              pages={pages}
            />
          }

        </ModalBody>

      </ModalContent>

    </Modal >
  )
}
