import {
  ModalOverlay,
  Modal,
  ModalBody,
  ModalContent,
  Text,
  VStack,
  Spinner,
} from '@chakra-ui/react'


interface IProps {
  isOpen?: boolean
  onClose?: () => void,
}

export const ModalLoadingMint = (props: IProps) => {

  const {
    isOpen,
    onClose,
  } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        onClose();
      }}
      isCentered={true}
    >

      <ModalOverlay
        backdropFilter='blur(3px)'
        backgroundColor="rgba(0, 0, 0, 0.5)"
      />

      <ModalContent
        bg="#fff"
        w="fit-content"
        p="30px 0px"
        borderRadius="8px"
        border={{ md: "1px solid #ffd3cb" }}
        boxShadow="0px 0px 14px rgba(186, 186, 186, 0.25)"
      >

        <ModalBody
          bg="transparent"

        >

          <VStack
            w="full"
            spacing={4}
            bg="transparent"
          >

            <Spinner color="red" size="xl"
            />

            <Text
              fontWeight={600}
              fontSize={{ base: "16px" }}
              lineHeight={{ base: "26px" }}
              textAlign={"center"}
            >
              Kindly hold on while we verify your Minting transaction
            </Text>

          </VStack>

        </ModalBody>

      </ModalContent>

    </Modal>
  )
}
