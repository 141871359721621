import { VStack, Text, Box, Image, Container, Heading, Stack, SimpleGrid, HStack } from "@chakra-ui/react"
import BoxLayout from "~/components/BoxLayout"
import MainLayout from "~/layouts/MainLayout"
import WrapperHeaderMobile from "~/layouts/MasterLayout/WrapperHeaderMobile"


import { QuestMissions } from "./QuestMissions";
import { XMissions } from "./XMissions";
import { MintsMission } from "./MintsMission";
import { ComingsoonMissions } from "./ComingsoonMissions";
import { DailyMissions } from "./DailyMissions";
import { LeaderboardMissions } from "./LeaderboardMissions";
import { useState } from "react";

import { MdHistory } from "react-icons/md";
import { LuBadgeInfo } from "react-icons/lu";

import { HistoryMissionsModal } from "./modal/HistoryMissionsModal";
import { MilestoneMissions } from "./MilestoneMissions";

import ImgPoint from "~/assets/images/banner_point.png"
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import CountUp from 'react-countup';
import { GalxeMission } from "./GalxeMission";
import { wlAccounts } from "~/common/code.helper";
import { OpGrantMissions } from "./OpGrantMissions";


export const MissionsView = () => {

  const [isOpen, setIsOpen] = useState(false);

  const { userInfo, account } = useConnectWallet();

  return (
    <MainLayout>

      <WrapperHeaderMobile />

      <BoxLayout>

        <Container maxW={'3xl'} py="30px">
          <Stack
            as={Box}
            textAlign={'center'}
          >

            <Heading
              fontWeight={600}
              fontSize={{ base: '2xl', sm: '4xl', md: '6xl' }}
              lineHeight={'110%'}
            >
              NFTFeed Point Hunter
            </Heading>

            <VStack
              mt="15px"
              overflow="hidden"
              alignItems={"start"}
              textAlign={{ base: "center", md: "start" }}
              spacing={4}
            >

              <Text color={'#000'}>
                Engage, interact, and mint your way through various activities to collect points and unlock thrilling rewards. Your dedication and creativity in the NFT universe will not only reward you with exclusive benefits but also prepare you for the grand spectacle awaiting you in Quarter 4, 2024.
              </Text>

            </VStack>

          </Stack>

        </Container>

        <VStack
          w="full"
        >

          <VStack
            w="full"
            maxW={"1000px"}
            spacing={6}
          >

            <Image
              src={ImgPoint}
              borderRadius="20px"
              loading={"lazy"}
            />

            <SimpleGrid
              w="full"
              bg="#fff"
              borderRadius="20px"
              border={{ base: "1px solid #000" }}
              boxShadow="0px 10px 0px 0px #ffd3cb"
              p={{ base: "10px", md: "20px" }}
              columns={{ base: 1, md: 2 }}
              spacing={4}
            >

              <VStack
                w="full"
                p="20px"
                bg="#ffd3cb9e"
                borderRadius="20px"
                border={{ base: "1px solid #000" }}
                alignItems={"start"}
              >

                <HStack
                  w='full'
                  justifyContent={"space-between"}
                >

                  <Text
                    color="#000"
                    fontWeight={700}
                    textAlign="center"
                    fontSize="16px"
                    lineHeight="24px"
                  >
                    My Points
                  </Text>

                  <HStack
                    bg="#fff"
                    borderRadius={"12px"}
                    p="2px 8px"
                    cursor={{ lg: "pointer" }}
                    onClick={() => {
                      setIsOpen(true)
                    }}
                  >
                    <MdHistory />
                    <Text
                      color="#000"
                      fontWeight={600}
                      textAlign="center"
                      fontSize="13px"
                    >
                      History
                    </Text>
                  </HStack>

                </HStack>


                <Text
                  color="#000"
                  fontWeight={700}
                  textAlign="center"
                  fontSize={{ md: "48px" }}
                  lineHeight="normal"
                >
                  <CountUp end={userInfo?.point} />
                </Text>

              </VStack>

              <VStack
                w="full"
                p="20px"
                bg="#ffd3cb9e"
                borderRadius="20px"
                border={{ base: "1px solid #000" }}
                alignItems={"start"}
              >

                <HStack
                  w='full'
                  justifyContent={"space-between"}
                >

                  <Text
                    color="#000"
                    fontWeight={700}
                    textAlign="center"
                    fontSize="16px"
                    lineHeight="24px"
                  >
                    My Rank
                  </Text>

                  <HStack
                    bg="#fff"
                    borderRadius={"12px"}
                    p="2px 8px"
                    cursor={{ lg: "pointer" }}
                    onClick={() => {
                      window.open("https://mirror.xyz/0x2368dB14Cf7F0F7f975bE690687687fC01F6D31E/WQqnyNPfKl2it-17MCCUlyBljAfqmi16PLenr1oDXD8")
                    }}
                  >
                    <LuBadgeInfo />
                    <Text
                      color="#000"
                      fontWeight={600}
                      textAlign="center"
                      fontSize="13px"
                    >
                      FAQ
                    </Text>
                  </HStack>

                </HStack>

                <Text
                  color="#000"
                  fontWeight={700}
                  textAlign="center"
                  fontSize={{ md: "48px" }}
                  lineHeight="normal"
                >
                  <CountUp end={parseInt(userInfo?.pointRank)} />
                </Text>

              </VStack>

            </SimpleGrid>

            <XMissions />

            <DailyMissions />

            {/* <OpGrantMissions /> */}

            <MilestoneMissions />

            <MintsMission />

            {account &&
              <GalxeMission />
            }

            <QuestMissions />

            <ComingsoonMissions />

            <LeaderboardMissions />


          </VStack>

        </VStack>

      </BoxLayout>

      <HistoryMissionsModal
        isOpen={isOpen}
        onClose={() => { setIsOpen(false) }}
      />

      <Box h="20vh" />

    </MainLayout>
  )

}
