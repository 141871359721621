import { Contract } from "ethers";
import { useCallback, useState } from "react"
import { configEnv } from "~/@config";
import { ApiException } from "~/@core/dto";
import { getExplorer } from "~/@web3-config/evm";
import { ERC1155, ERC1155__factory } from "~/abi";
import { getMessageErrorBlockChain } from "~/common/block-chain.helper";
import { useConnectWallet } from "~/hooks/@global/useConnectWallet";
import useCustomToast from "~/hooks/@global/useCustomToast";

export const useTokenTools = () => {

  const [isLoading, setIsLoading] = useState(false);
  const toast = useCustomToast();

  const { provider, chainId } = useConnectWallet();


  const setApprovalForAllNft = useCallback(async ({ nftAddress, operator }: { nftAddress: string, operator: string }, funcRunOk?: () => void) => {

    setIsLoading(true);
    try {

      const signer = provider.getSigner();
      const poolFactory = new Contract(
        nftAddress,
        ERC1155__factory.abi,
        signer
      ) as ERC1155;
      const { transactionHash } = await (
        await poolFactory.connect(signer).setApprovalForAll(
          operator,
          true
        ).catch(err => {
          throw new ApiException(getMessageErrorBlockChain(err))
        })
      ).wait()
        .catch(err => {
          throw new ApiException(getMessageErrorBlockChain(err))
        });

      toast.success({
        subTitle: 'Update price collection success',
        description: `${getExplorer(chainId)}/tx/${transactionHash}`.trim(),
      })
      if (funcRunOk) {
        funcRunOk();
      }
    } catch (error) {
      console.log(`-------------------`);
      console.log(error);
      console.log(`-------------------`);
      toast.handleError(error)
    }

    setIsLoading(false);
  }, [chainId, provider, toast]);


  return {
    isLoading,
    setApprovalForAllNft
  }
}
