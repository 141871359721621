import { useState, useCallback, useEffect } from 'react';
import { getMessageErrorBlockChain, parseAmountToken } from '~/common/block-chain.helper';
import { ApiException } from '~/@core/dto';
import { getExplorer } from '~/@web3-config/evm';
import { Contract } from "ethers";
import { configEnv } from "~/@config";
import useCustomToast from '~/hooks/@global/useCustomToast';
import { useConnectWallet } from '~/hooks/@global/useConnectWallet';
import { FeedAgg__factory } from '~/abi/factories/FeedAgg__factory';
import { FeedAgg } from '~/abi/FeedAgg';
import userService from '~/services/user.service';
import { useNavigate } from 'react-router-dom';
import { MAIN_ROUTERS } from '~/routes/routes';

export const useCrawlAgg = () => {

  const toast = useCustomToast();
  const [isLoading, setIsLoading] = useState(false);
  const { account, chainId, provider } = useConnectWallet();
  const navigate = useNavigate();

  const [selectChain, setSelectChain] = useState(null);
  const [keySearch, setKeySearch] = useState("");
  const [txhHash, setTxhHash] = useState("");

  const crawl = useCallback(async () => {
    setIsLoading(true);
    try {

      if (account) {

        const signer = provider.getSigner();

        // thu phí
        {
          const { FEED_AGG } = configEnv().customSMC[chainId]
          const rexFeeCt = new Contract(FEED_AGG, FeedAgg__factory.abi, provider) as FeedAgg;
          const value = await rexFeeCt.crawlFee();

          const { transactionHash } = await (
            await rexFeeCt.connect(signer).crawl(keySearch, { value }).catch(err => {
              throw new ApiException(getMessageErrorBlockChain(err))
            })
          ).wait()
            .catch(err => {
              throw new ApiException(getMessageErrorBlockChain(err))
            });
          // console.log(`---------TX take Fee----------`);
          // console.log({ transactionHash });
          // console.log(`-------------------`);
        }

        const rs = await userService.crawlAgg({
          chainId,
          evmAddress: account,
          address: keySearch,
        });

        // console.log({ rs });

        toast.success({
          title: "Success",
          subTitle: 'Crawl Success',
        })

        if (rs && rs.address) {
          navigate(`${MAIN_ROUTERS.NFT_COLLECTION}/${chainId}/${rs.address}`);
        }

        setIsLoading(false);

      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);

      toast.handleErrorBlockChain(error)
    }

    setIsLoading(false);
  }, [account, chainId, keySearch, provider,]);

  return {
    isLoading,
    crawl,
    selectChain,
    setSelectChain,
    keySearch,
    setKeySearch,
    txhHash,
    setTxhHash,
  }
}

