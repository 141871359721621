import { SVGProps } from 'react'

const TwitterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.702 4.115a7.773 7.773 0 0 1-2.229.61 3.892 3.892 0 0 0 1.707-2.148 7.75 7.75 0 0 1-2.465.942A3.883 3.883 0 0 0 10.1 7.06a11.024 11.024 0 0 1-8.002-4.056A3.883 3.883 0 0 0 3.3 8.187a3.872 3.872 0 0 1-1.758-.486v.05a3.883 3.883 0 0 0 3.114 3.807 3.91 3.91 0 0 1-1.754.067A3.884 3.884 0 0 0 6.53 14.32a7.79 7.79 0 0 1-4.822 1.662c-.31 0-.619-.018-.926-.054a10.979 10.979 0 0 0 5.95 1.744c7.141 0 11.046-5.915 11.046-11.045 0-.167-.005-.335-.012-.502A7.892 7.892 0 0 0 19.7 4.117l.001-.002Z"
      fill="#5F6774"
    />
  </svg>
)

export default TwitterIcon
